import React from "react";
import { getCommandParams, getFilters, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { changeToBuffer, setChange, setFilter, setObjectState } from "../../../redux/features/ui/uiSlice";
import { calculate } from "../../../services/calculate";
import { TiDeleteOutline } from "react-icons/ti";
import { BsCalendar3 } from "react-icons/bs";

const S1DateTime = ({ element, tabID, screenState, currentLine }) => {
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const model = screenState == "form" && formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0];
  const data = useSelector((state) => getRecData(state, tabID));
  const tableIndex = formDesign?.model?.[table]?.relationship == "OneToOne" ? 0 : currentLine?.index ?? 0;

  const dateRef = React.useRef(null);
  const contRef = React.useRef(null);

  const [clear, showClear] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [controlValue, setControlValue] = React.useState("");

  const handleCalculate = async (table, key, value) => {
    dispatch(setChange({ tabID, table, currentLine, key, value }));
    model?.updates && (await calculate(tabID, objectState));
  };

  const handleClear = async () => {
    setValue("");
    setControlValue("");
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else {
      if (objectState == "view") {
        await dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ tabID, value: "edit" }));
      }
      handleCalculate(table, key, "");
    }
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(dateTimeUIConverter(element.value));
      setControlValue(dateTimeUIConverter(element.value));
      dispatch(setFilter({ tabID, table, key, value: element.value }));
    }
  }, [screenState]);

  React.useEffect(() => {
    if (screenState == "form") {
      if (data?.[table]?.[tableIndex]?.[key] && data?.[table]?.[tableIndex]?.[key] != "") {
        setValue(dateTimeUIConverter(data?.[table]?.[tableIndex]?.[key]));
        setControlValue(dateTimeUIConverter(data?.[table]?.[tableIndex]?.[key]));
      } else {
        setValue("");
        setControlValue("");
      }
    }
  }, [data?.[table]?.[tableIndex]?.[key]]);

  const handleKeyDown = (e) => {
    if ((e.target.value.endsWith("/") || e.target.value.endsWith(":")) && e.keyCode == 8) {
      const vv = e.target.value;
      const slice1 = vv.slice(0, -1);
      const slice2 = vv.slice(0, -2);
      if (slice2.endsWith("0")) {
        setValue(e.target.value.slice(0, -2));
      } else {
        setValue(e.target.value.slice(0, -1));
      }
    } else if (e.target.value.endsWith(" ") && e.keyCode == 8) {
      const vv = e.target.value;
      setValue(e.target.value.slice(0, -1));
    }
  };

  const handleChange = async (e) => {
    let val = e.target.value.replace(/[^\d\s/:]/g, "");
    const parts = val.split(/[\s/:]/);
    let day, month, hour, minute;
    if (parts.length > 0) {
      day = checkDay(parts[0]);
      if (!day.success) return;
      val = day.value;
    }

    if (parts.length > 1) {
      day = checkDay(parts[0]);
      month = checkMonth(parts[1]);
      if (!month.success) return;
      val = `${day.value}${month.value}`;
    }

    if (parts.length > 2) {
      day = checkDay(parts[0]);
      month = checkMonth(parts[1]);
      if (!day.success) return;
      if (!month.success) return;
      val = `${parts[0]}/${parts[1]}/${parts[2].length == 4 ? parts[2] + " " : parts[2]}`;
    }

    if (parts.length > 3) {
      day = checkDay(parts[0]);
      month = checkMonth(parts[1]);
      if (!day.success) return;
      if (!month.success) return;
      hour = checkHour(parts[3]);
      if (!hour.success) return;
      val += `${hour.value}`;
    }

    if (parts.length > 4) {
      day = checkDay(parts[0]);
      month = checkMonth(parts[1]);
      if (!day.success) return;
      if (!month.success) return;
      minute = checkMinute(parts[4]);
      if (!minute.success) return;
      val += `${minute.value}`;
    }

    setValue(val);
  };

  const handleBlur = async () => {
    setIsFocused(false);
    let val;
    const today = new Date();
    let parts = value.split(/[\s/:]/);
    if (parts[0] > 0) {
      parts[0] = parts[0].padStart(2, "0");

      if (parts.length > 1) {
        parts[1] = parts[1] > 0 ? parts[1].padStart(2, "0") : (today.getMonth() + 1).toString().padStart(2, "0");
      } else {
        parts[1] = (today.getMonth() + 1).toString().padStart(2, "0");
      }

      if (parts.length > 2) {
        if (parts[2] == "") {
          parts[2] = today.getFullYear();
        } else if (parts[2] < 100) {
          parts[2] = 2000 + Number(parts[2]);
        } else {
          parts[2] = `0000${Number(parts[2])}`.slice(-4);
        }
      } else {
        parts[2] = today.getFullYear();
      }

      if (parts.length > 3) {
        parts[3] = parts[3].padStart(2, "0");
      } else {
        parts[3] = today.getHours().toString().padStart(2, "0");
      }

      if (parts.length > 4) {
        parts[4] = parts[4].padStart(2, "0");
      } else {
        parts[4] = today.getMinutes().toString().padStart(2, "0");
      }

      val = `${parts[0]}/${parts[1]}/${parts[2]} ${parts[3]}:${parts[4]}`;

      if (
        data?.[table]?.[tableIndex]?.[key] !=
        (isValidDateTime(val) ? dateTimeConverter(parts[2], parts[1] - 1, parts[0], parts[3], parts[4]) : "")
      ) {
        if (screenState == "dialog") {
          setValue(isValidDateTime(val) ? val : "");
          dispatch(
            setFilter({
              tabID,
              table,
              key,
              value: isValidDateTime(val)
                ? dateTimeConverter(parts[2], parts[1] - 1, parts[0], parts[3], parts[4])
                : "",
            })
          );
        } else if (screenState == "form") {
          if (objectState == "view") {
            await dispatch(changeToBuffer({ tabID }));
            dispatch(setObjectState({ tabID, value: "edit" }));
          }
          handleCalculate(
            table,
            key,
            isValidDateTime(val) ? dateTimeConverter(parts[2], parts[1] - 1, parts[0], parts[3], parts[4]) : ""
          );
        }
      }
      setValue(val);
    } else {
      if (data?.[table]?.[tableIndex]?.[key] != "") {
        if (screenState == "dialog") {
          setValue("");
          dispatch(
            setFilter({
              tabID,
              table,
              key,
              value: "",
            })
          );
        } else if (screenState == "form") {
          if (objectState == "view") {
            await dispatch(changeToBuffer({ tabID }));
            dispatch(setObjectState({ tabID, value: "edit" }));
          }
          handleCalculate(table, key, "");
        }
      }
      setValue("");
    }
  };

  const handleChangeFromControl = async (value) => {
    setValue(dateTimeUIConverter(value));
    const date = new Date(value);

    if (screenState == "dialog") {
      dispatch(
        setFilter({
          tabID,
          table,
          key,
          value: dateTimeConverter(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes()
          ),
        })
      );
    } else if (screenState == "form") {
      if (objectState == "view") {
        await dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ tabID, value: "edit" }));
      }
      handleCalculate(
        table,
        key,
        dateTimeConverter(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes())
      );
    }
  };

  return (
    <div
      className="s1datetime w-100"
      ref={contRef}
      style={{
        flex: `1 1 ${element.flex * 100}%`,
        overflow: "hidden",
        visibility: element.visible ? "visible" : "hidden",
      }}
    >
      <div className="inputGroup">
        {element.caption != "" && (
          <label className="text-truncate" htmlFor={element.index}>
            {`${element.caption}:`}
          </label>
        )}
        <div
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}${
            element.required && value == "" ? " required" : ""
          }`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
          role="button"
        >
          <div className="d-flex w-100" onMouseEnter={() => showClear(true)} onMouseLeave={() => showClear(false)}>
            <input
              type="text"
              className={`w-100${value != "" ? " date-input-has-value" : ""}${
                element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
              }`}
              disabled={element.readOnly || (screenState == "form" && commandParams.readonly)}
              readOnly={element.readOnly || (screenState == "form" && commandParams.readonly)}
              value={value}
              name={element.name}
              onChange={(e) => !element.readOnly && handleChange(e)}
              onKeyDown={(e) => !element.readOnly && handleKeyDown(e)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => handleBlur()}
              autoComplete="off"
            />
            {value != "" && clear && !element.readOnly && (
              <div onClick={handleClear}>
                <TiDeleteOutline size="30px" role="button" />
              </div>
            )}
          </div>
          {!(element.readOnly || (screenState == "form" && commandParams.readonly)) && (
            <div
              style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
              onClick={() => {
                dateRef.current.disabled = false;
                dateRef.current.showPicker();
                dateRef.current.click();
                dateRef.current.focus();
              }}
            >
              <BsCalendar3
                className={element.readOnly || (screenState == "form" && commandParams.readonly) ? "disabled" : ""}
                color="rgb(64, 180, 252)"
                size="20px"
              />
            </div>
          )}
        </div>
      </div>
      <input
        type="datetime-local"
        ref={dateRef}
        value={dateTimeCONTROLConverter(controlValue)}
        className="text-truncate"
        disabled={true}
        onBlur={() => (dateRef.current.disabled = true)}
        onChange={(e) => !element.readOnly && handleChangeFromControl(e.target.value)}
        style={{
          pointerEvents: "none",
          position: "fixed",
          top: contRef?.current?.getBoundingClientRect()?.bottom,
          maxWidth: 0,
          maxHeight: 0,
          backgroundColor: "transparent",
          border: 0,
          color: "transparent",
        }}
      />
    </div>
  );
};

export default S1DateTime;

const dateTimeConverter = (year, month, day, hour, minute) => {
  var d = new Date(year, month, day, hour, minute);
  return `${[d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-")} ${[
    d.getHours().padLeft(),
    d.getMinutes().padLeft(),
    d.getMilliseconds().padLeft(),
  ].join(":")}`;
};

const dateTimeUIConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return `${[d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/")} ${[
      d.getHours().padLeft(),
      d.getMinutes().padLeft(),
    ].join(":")}`;
  } else {
    return value;
  }
};

const dateTimeCONTROLConverter = (value) => {
  if (value != "") {
    let [day, month, year, hour, minute] = value.split(/[\s/:]/);
    var d = new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute));
    return `${[d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-")}T${[
      d.getHours().padLeft(),
      d.getMinutes().padLeft(),
    ].join(":")}`;
  } else {
    return value;
  }
};

const checkDay = (day) => {
  if (day > 31) {
    return { success: false };
  } else if (day > 3 || day.length == 2) {
    return { success: true, value: `${day}/` };
  } else {
    return { success: true, value: `${day}` };
  }
};

const checkMonth = (month) => {
  if (month > 12) {
    return { success: false };
  } else if ((month > 1 && month <= 12) || month.length == 2) {
    return { success: true, value: `${month}/` };
  } else {
    return { success: true, value: `${month}` };
  }
};

const checkHour = (hour) => {
  if (hour > 23) {
    return { success: false };
  } else if (hour.length === 2 || hour > 2) {
    return { success: true, value: `${hour}:` };
  } else {
    return { success: true, value: hour };
  }
};

const checkMinute = (minute) => {
  if (minute > 59) {
    return { success: false };
  } else if (minute.length === 2 || minute > 5) {
    return { success: true, value: minute };
  } else {
    return { success: true, value: minute };
  }
};

const isValidDateTime = (val) => {
  const dateTimePattern = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/;
  if (!dateTimePattern.test(val)) return false;

  const [datePart, timePart] = val.split(" ");
  const [day, month, year] = datePart.split("/").map(Number);
  const [hour, minute] = timePart.split(":").map(Number);
  const date = new Date(year, month - 1, day, hour, minute);

  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day &&
    date.getHours() === hour &&
    date.getMinutes() === minute
  );
};
