import { getSN } from "../../redux/selectors";
import store from "../../redux/store";

export const locateInfoF = (formDesign) => {
  const state = store.getState();
  const sn = getSN(state);
  var locateinfo = "";
  Object.entries(formDesign.model).forEach(([key, value], index) => {
    locateinfo += index == 0 ? `${key}:` : `;${key}:`;
    if (key == "XTRDOCDATA") locateinfo += "NAME,SOFNAME,LINENUM";
    else {
      value.fields.map((item, index) => {
        if (sn == "01104356985223" && key == "ACTLINES" && index == 0) {
          locateinfo +=
            "MTRL_MTRL_MTRMARK,MTRL_MTRL_MTRMODEL,CCCDESMI,CCCENERGY,CCCPULSE,CCCSPRAY,CCCDELAY,CCCPOST,CCCMESLEFTARM,CCCMESLEFTFLAGS,CCCMESLEFTLEGUP,CCCMESLEFTLEGMID,CCCMESLEFTLEGDOWN,CCCMESLEFTCALF,CCCMESRIGHTARM,CCCMESRIGHTFLAGS,CCCMESRIGHTLEGUP,CCCMESRIGHTLEGMID,CCCMESRIGHTLEGDOWN,CCCMESRIGHTCALF,CCCMESMIDBREAST,CCCMESMIDYHYPEROMF,CCCMESMIDOMF,CCCMESMIDSUBOMF,CCCMESMIDPERIF,CCCKEFLEFTPERIOXI,CCCKEFLEFTANAROF,CCCKEFRIGHTPERIOXI,CCCKEFRIGHTANAROF,";
        }
        locateinfo += `${item.name},`;
      });
    }
  });
  return locateinfo;
};
