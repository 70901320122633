import { createSlice } from "@reduxjs/toolkit";

const initialState = JSON.parse(localStorage.getItem("session"));

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    set: (state, action) => {
      localStorage.setItem("session", JSON.stringify(action.payload));
      return { ...state, ...action.payload };
    },
    setBlcDate: (state, action) => {
      state.blcdate = action.payload;
      localStorage.setItem("session", JSON.stringify(state));
    },
    clear: () => {
      localStorage.setItem("session", null);
      return null;
    },
  },
});

export const { set, clear, setBlcDate } = sessionSlice.actions;

export default sessionSlice.reducer;
