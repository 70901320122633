import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { set, setDarkMode } from "../../../redux/features/settings/settingsSlice";
import CrmCheckBox from "../custom inputs/CrmCheckBox";
import CrmInteger from "../custom inputs/CrmInteger";
import NotificationPosition from "../custom inputs/NotificationPosition";

const App = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.app);
  const darkMode = useSelector((state) => state.settings.darkMode);

  const [menuOpen, setMenuOpen] = React.useState(settings.menuOpen == 1);
  const [doubleClickOnMenu, setDoubleClickOnMenu] = React.useState(settings.doubleClickOnMenu == 1);
  const [popUpRedirection, setPopUpRedirection] = React.useState(settings.popUpRedirection == 1);
  const [clickToSort, setClickToSort] = React.useState(settings.clickToSort == 1);
  const [gridClickToSort, setGridClickToSort] = React.useState(settings.gridClickToSort == 1);
  const [singleClickOnRecord, setSingleClickOnRecord] = React.useState(settings.singleClickOnRecord == 1);
  const [confirmExit, setConfirmExit] = React.useState(settings.confirmExit == 1);
  const [browserSearchActive, setBrowserSearchActive] = React.useState(settings.browserSearchActive == 1);
  const [askForDeleteInGrid, setAskForDeleteInGrid] = React.useState(settings.askForDeleteInGrid == 1);
  const [enablePortal, setEnablePortal] = React.useState(settings.enablePortal == 1);

  const inputs = [
    {
      caption: "Κεντρικό μενού ανοιχτό",
      type: "checkBox",
      name: "menuOpen",
      value: menuOpen,
      change: (e) => {
        setMenuOpen(e.target.checked);
        dispatch(set({ key: "menuOpen", value: e.target.checked ? 1 : 0 }));
      },
    },
    {
      caption: "Ανοιγμα επιλογών μενού με διπλό κλικ",
      type: "checkBox",
      name: "doubleClickOnMenu",
      value: doubleClickOnMenu,
      change: (e) => {
        setDoubleClickOnMenu(e.target.checked);
        dispatch(set({ key: "doubleClickOnMenu", value: e.target.checked ? 1 : 0 }));
      },
    },
    {
      caption: "Redirection (0=Tab,1=PopUp)",
      type: "checkBox",
      name: "doubleClickOnMenu",
      value: popUpRedirection,
      change: (e) => {
        setPopUpRedirection(e.target.checked);
        dispatch(set({ key: "popUpRedirection", value: e.target.checked ? 1 : 0 }));
      },
    },
    {
      caption: "Browser record limit",
      type: "int",
      name: "browserLimit",
    },
    {
      caption: "Browser page size",
      type: "int",
      name: "browserPageSize",
    },
    {
      caption: "Grid visible lines",
      type: "int",
      name: "gridVisibleLines",
    },
    {
      caption: "Ταξινόμηση με click σε ευρετήρια",
      type: "checkBox",
      name: "clickToSort",
      value: clickToSort,
      change: (e) => {
        setClickToSort(e.target.checked);
        dispatch(set({ key: "clickToSort", value: e.target.checked ? 1 : 0 }));
      },
    },
    {
      caption: "Ταξινόμηση με click σε πλέγμα",
      type: "checkBox",
      name: "gridClickToSort",
      value: gridClickToSort,
      change: (e) => {
        setGridClickToSort(e.target.checked);
        dispatch(set({ key: "gridClickToSort", value: e.target.checked ? 1 : 0 }));
      },
    },
    {
      caption: "Ανοιγμα εγγραφών με single click",
      type: "checkBox",
      name: "singleClickOnRecord",
      value: singleClickOnRecord,
      change: (e) => {
        setSingleClickOnRecord(e.target.checked);
        dispatch(set({ key: "singleClickOnRecord", value: e.target.checked ? 1 : 0 }));
      },
    },
    {
      caption: "Επιβεβαίωση εξόδου",
      type: "checkBox",
      name: "confirmExit",
      value: confirmExit,
      change: (e) => {
        setConfirmExit(e.target.checked);
        dispatch(set({ key: "confirmExit", value: e.target.checked ? 1 : 0 }));
      },
    },
    {
      caption: "Διατήρηση browser active search",
      type: "checkBox",
      name: "browserSearchActive",
      value: browserSearchActive,
      change: (e) => {
        setBrowserSearchActive(e.target.checked);
        dispatch(set({ key: "browserSearchActive", value: e.target.checked ? 1 : 0 }));
      },
    },
    {
      caption: "Ερώτηση για διαγραφή σε πλέγμα",
      type: "checkBox",
      name: "askForDeleteInGrid",
      value: askForDeleteInGrid,
      change: (e) => {
        setAskForDeleteInGrid(e.target.checked);
        dispatch(set({ key: "askForDeleteInGrid", value: e.target.checked ? 1 : 0 }));
      },
    },
    {
      caption: "Ενεργοποίηση Portal",
      type: "checkBox",
      name: "enablePortal",
      value: enablePortal,
      change: (e) => {
        setEnablePortal(e.target.checked);
        dispatch(set({ key: "enablePortal", value: e.target.checked ? 1 : 0 }));
      },
    },
    {
      caption: "Dark Mode",
      type: "checkBox",
      name: "darkMode",
      value: darkMode == 1,
      change: (e) => {
        dispatch(setDarkMode(e.target.checked ? 1 : 0));
      },
    },
  ];

  return (
    <div style={{ flex: 1 }} className="d-flex flex-column">
      {inputs.map((element, index) => {
        switch (element.type) {
          case "checkBox":
            return (
              <CrmCheckBox
                key={index}
                change={element.change}
                caption={element.caption}
                name={element.name}
                value={element.value}
              />
            );
          case "int":
            return <CrmInteger key={index} caption={element.caption} name={element.name} />;

          default:
            return;
        }
      })}
      <NotificationPosition />
    </div>
  );
};

export default App;
