import { createSlice } from "@reduxjs/toolkit";
import {
  setAccessRights,
  setCustomRelJobMenuProperty,
  setDialog,
  setFormDesign,
  setPrepareNew,
  setRelJobMenu,
  setTableFields,
} from "../ui/uiSlice";
import { clear } from "../session/sessionSlice";

var data = {};
const recursive = (element) => {
  if (element.items ?? [].length > 0) {
    element.items.forEach((item) => recursive(item));
  } else {
    if (element.value) {
      data = {
        ...data,
        [element.name.split(".")[0]]: {
          ...data?.[element.name.split(".")[0]],
          [element.name.split(".")[1]]: element.value,
        },
      };
    }
  }
};

const initialState = {
  currentscreen: "dashboard",
  screens: {
    dashboard: "dashboard",
    user: "user",
  },
};

const screensSlice = createSlice({
  name: "screens",
  initialState,
  reducers: {
    setWebMenu: (state, action) => {
      state.screens = action.payload;
    },
    setScreenFromJob: (state, action) => {
      state.screens[action.payload.key] = {
        ...state.screens[action.payload.key],
        ...action.payload.value,
      };
    },
    setLastSelection: (state, action) => {
      const listofKeys =
        state.screens[action.payload.key]?.lastSelections?.[action.payload.view]?.[action.payload.field]?.keys || [];
      if (listofKeys.indexOf(action.payload.value) > -1) {
        const element = listofKeys.splice(listofKeys.indexOf(action.payload.value), 1)[0];
        listofKeys.unshift(element);
        state.screens[action.payload.key] = {
          ...state.screens[action.payload.key],
          lastSelections: {
            ...state.screens[action.payload.key].lastSelections,
            [action.payload.view]: {
              ...state.screens[action.payload.key].lastSelections?.[action.payload.view],
              [action.payload.field]: {
                ...state.screens[action.payload.key]?.[action.payload.field],
                keys: listofKeys,
                values: {
                  ...state.screens[action.payload.key]?.lastSelections?.[action.payload.view]?.[action.payload.field]
                    ?.values,
                  [action.payload.value]: { text: action.payload.text, row: action.payload.row },
                },
              },
            },
          },
        };
      } else {
        listofKeys.unshift(action.payload.value);
        state.screens[action.payload.key] = {
          ...state.screens[action.payload.key],
          lastSelections: {
            ...state.screens[action.payload.key].lastSelections,
            [action.payload.view]: {
              ...state.screens[action.payload.key].lastSelections?.[action.payload.view],
              [action.payload.field]: {
                ...state.screens[action.payload.key]?.[action.payload.field],
                values: {
                  ...state.screens[action.payload.key]?.lastSelections?.[action.payload.view]?.[action.payload.field]
                    ?.values,
                  [action.payload.value]: { text: action.payload.text, row: action.payload.row },
                },
                keys: listofKeys,
              },
            },
          },
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setAccessRights, (state, action) => {
        state.screens[action.payload.idx].accessRights = action.payload.value;
      })
      .addCase(setDialog, (state, action) => {
        data = {};
        if (action.payload.value.success) {
          action.payload.value.form.forEach((element) => recursive(element));
        }
        state.screens[action.payload.idx].dialog = {
          ...action.payload.value,
          filters: {
            data,
          },
        };
      })
      .addCase(setFormDesign, (state, action) => {
        state.screens[action.payload.idx].formDesign = action.payload.value;
      })
      .addCase(setRelJobMenu, (state, action) => {
        state.screens[action.payload.idx].relJobMenu = action.payload.value;
      })
      .addCase(setCustomRelJobMenuProperty, (state, action) => {
        state.screens[action.payload.idx].customRelJobs = {
          ...state.screens[action.payload.idx].customRelJobs,
          [action.payload.key]: action.payload.value,
        };
      })
      .addCase(setTableFields, (state, action) => {
        state.screens[action.payload.idx].tableFields = action.payload.value;
      })
      .addCase(setPrepareNew, (state, action) => {
        state.screens[action.payload.idx].prepareNew = action.payload.value;
      })
      .addCase(clear, () => initialState);
  },
});

export const { setWebMenu, setScreenFromJob, setLastSelection } = screensSlice.actions;

export default screensSlice.reducer;
