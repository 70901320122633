import React from "react";
import { BsChevronDown, BsSearch } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/features/ui/uiSlice";
import getSelectorData from "../../../services/getSelectorData";
import { getCommandParams, getFilters } from "../../../redux/selectors";
import PickerSpinner from "../PickerSpinner";

const S1MultiSelect = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => getFilters(state, tabID));

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const [pickerLoading, setPickerLoading] = React.useState(false);
  const [selectorData, setSelectorData] = React.useState({});
  const [error, setError] = React.useState("");
  const [selectedRow, setSelectedRow] = React.useState("");
  const [returnIndex, setReturnIndex] = React.useState(-1);

  const boundRef = React.useRef(null);
  const pickerRef = React.useRef(null);
  const searchRef = React.useRef(null);
  const tableRef = React.useRef(null);

  const [searchText, setSearchText] = React.useState("");
  const [hoverIndex, setHoverIndex] = React.useState(-1);

  const [clear, showClear] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(filters?.data?.[table]?.[key] ?? "");
      setSelectedRow(filters?.data?.[table]?.[key] ?? "");
    }
  }, [filters?.data?.[table]?.[key]]);

  React.useEffect(() => {
    setHoverIndex(-1);
    setSearchText("");
    if (openPicker) {
      searchRef.current.focus();
      const clickListener = (e) => {
        const cliii = pickerRef.current.contains(e.target) || boundRef.current.contains(e.target);
        if (!cliii) {
          setOpenPicker(false);
          setIsFocused(false);
        }
      };

      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [openPicker]);

  const handlePicker = async (e) => {
    e.stopPropagation();
    setOpenPicker((x) => !x);
    if (!openPicker) {
      setIsFocused(true);
      setPickerLoading(true);
      const EditorArguments = Array.from(element.editor.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
      var editor = element.editor;
      EditorArguments.forEach((y) => {
        var splitted = y.split(".");
        var tableName = splitted[0];
        var fieldName = splitted[1];
        var replaceValue =
          fieldName == "COMPANY" ? ":X.SYS.MAINCOMPANY" : filters?.data?.[tableName]?.[fieldName].split("|")[0] ?? "";
        editor = editor.replaceAll(`[${y}]`, replaceValue);
      });
      const GetSelectorData = await getSelectorData({ editor, value: "", tabID });
      if (GetSelectorData.success) {
        console.log(GetSelectorData);
        console.log(element);
        if (element.selectorVF != "") {
          setReturnIndex(
            GetSelectorData.model.findIndex((object) => object.name == element.selectorVF.split("|")[0].split(".")[1])
          );
        } else {
          setReturnIndex(
            GetSelectorData.model.findIndex(
              (object) => object.name == element.editor.split("|")[2] || object.name == element.editor.split("|")[1]
            )
          );
        }
        setSelectorData(GetSelectorData);
      } else {
        setSelectorData({ success: false });
        setError(GetSelectorData.error);
      }
      setPickerLoading(false);
    } else {
      setIsFocused(false);
    }
  };

  const handleClear = () => {
    setSelectedRow("");
    setValue("");
    setOpenPicker(false);
    setIsFocused(false);
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else {
      console.log("dispatch se " + screenState);
    }
  };

  const handleClickOnRow = (index) => {
    console.log(index);
    const SelectedArray = selectedRow.split(",");
    if (SelectedArray.indexOf(index) >= 0) {
      SelectedArray.splice(SelectedArray.indexOf(index), 1);
    } else {
      SelectedArray.push(index);
    }
    // setSelectedRow(SelectedArray.filter((x) => x != "").join(","));
    // setValue(SelectedArray.filter((x) => x != "").join(","));
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: SelectedArray.filter((x) => x != "").join(",") }));
    } else {
      console.log("dispatch se " + screenState);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    if (selectorData?.success) {
      var filter, td, i, txtValue;
      var is = [];
      filter = value.toUpperCase();
      td = tableRef.current.querySelectorAll(".searchable");
      for (i = 0; i < td.length; i++) {
        td[i].parentElement.style.display = "none";
        txtValue = td[i].textContent || td[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          is.push(td[i].parentElement);
        } else {
          td[i].parentElement.style.display = "none";
        }
        is.forEach((t) => (t.style.display = ""));
      }
    }
  };

  return (
    <div
      className="s1multiselect w-100"
      style={{
        flex: `1 1 ${element.flex * 100}%`,
        overflow: "hidden",
        visibility: element.visible ? "visible" : "hidden",
      }}
    >
      <div className="inputGroup">
        <label className="text-truncate" htmlFor={element.index}>
          {`${element.caption}:`}
        </label>
        <div
          ref={boundRef}
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}${
            element.required && value == "" ? " required" : ""
          }`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
          role="button"
        >
          <div className="d-flex w-100" onMouseEnter={() => showClear(true)} onMouseLeave={() => showClear(false)}>
            <input
              role="button"
              className={`w-100${
                element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
              }`}
              disabled={element.readOnly || (screenState == "form" && commandParams.readonly)}
              readOnly={true}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              // onFocus={() => setIsFocused(true)}
              // onBlur={() => setIsFocused(false)}
              onClick={
                !(element.readOnly || (screenState == "form" && commandParams.readonly)) ? handlePicker : undefined
              }
            />
            {value != "" &&
              (clear || openPicker) &&
              !(element.readOnly || (screenState == "form" && commandParams.readonly)) && (
                <div onClick={handleClear}>
                  <TiDeleteOutline size="30px" role="button" />
                </div>
              )}
          </div>
          <div
            style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
            role="button"
            onClick={element.readOnly || (screenState == "form" && commandParams.readonly) ? undefined : handlePicker}
          >
            <BsChevronDown
              className={`${element.readOnly || (screenState == "form" && commandParams.readonly) ? "disabled" : ""}`}
              color="rgb(64, 180, 252)"
              size="20px"
            />
          </div>
        </div>
      </div>
      {openPicker && (
        <div
          className="input-picker-div"
          ref={pickerRef}
          style={{
            left: boundRef.current.getBoundingClientRect().left,
            width: boundRef.current.getBoundingClientRect().width,
            top:
              boundRef.current.getBoundingClientRect().bottom -
              (boundRef.current.getBoundingClientRect().bottom + 300 > window.innerHeight
                ? 300 + boundRef.current.getBoundingClientRect().height
                : 0),
            height: "300px",
            overflow: "hidden",
          }}
        >
          {pickerLoading && <PickerSpinner />}
          <div>
            <div className="inputGroup m-0 gap-1" style={{ borderBottom: "1px solid black" }}>
              <div className="ms-1 d-flex justify-content-center align-items-center">
                <BsSearch color="rgb(64, 180, 252)" />
              </div>
              <input
                ref={searchRef}
                style={{ height: "30px" }}
                placeholder="Αναζήτηση"
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
              />
              {searchText != "" && (
                <div
                  onClick={() => {
                    handleSearch("");
                  }}
                >
                  <TiDeleteOutline size="30px" role="button" />
                </div>
              )}
            </div>
          </div>
          <div
            className="input-picker w-100 h-100"
            style={{
              overflow: "auto",
            }}
          >
            {selectorData?.success ? (
              <table className="input-picker-table w-100" ref={tableRef}>
                <tbody>
                  {Object.keys(selectorData.data).map((key, index) => {
                    return (
                      <tr
                        key={index}
                        className={`input-picker-table-row${
                          selectedRow != "" && selectedRow.split(",").indexOf(selectorData.data[key][returnIndex]) >= 0
                            ? " input-picker-table-row-selected"
                            : ""
                        }${hoverIndex == index ? " hovered" : ""}`}
                        onClick={() => handleClickOnRow(selectorData.data[key][returnIndex])}
                      >
                        <td className="p-1" style={{ height: "40px" }}>
                          <input
                            role="button"
                            type="checkbox"
                            className="ms-1"
                            readOnly={true}
                            checked={
                              selectedRow != "" &&
                              selectedRow.split(",").indexOf(selectorData.data[key][returnIndex]) >= 0
                            }
                          />
                        </td>
                        {selectorData.data[key].map((item, index) => {
                          return (
                            <td className="p-1 searchable" key={index} style={{ height: "40px" }}>
                              {item}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100">{error}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default S1MultiSelect;
