import React from "react";
import { useSelector } from "react-redux";
import { getReportData, getReportInfo, getScreenState } from "../../../redux/selectors";
import ReportFooter from "./ReportFooter";
import ErrorDisplay from "../ErrorDisplay";
import NoBrowserData from "../NoBrowserData";
import store from "../../../redux/store";
import { getRootsByKey } from "../../../redux/selectors/roots";
import { useDispatch } from "react-redux";
import { setScreenFromJob } from "../../../redux/features/screens/screensSlice";
import { setNewTab } from "../../../redux/features/ui/uiSlice";

const Browser = ({ tabID }) => {
  const dispatch = useDispatch();

  const reportInfo = useSelector((state) => getReportInfo(state, tabID));
  const reportData = useSelector((state) => getReportData(state, tabID));
  const popUpRedirection = useSelector((state) => state.settings.app?.popUpRedirection);

  const reportRef = React.useRef(null);

  React.useEffect(() => {
    if (reportRef.current) {
      const rows = reportRef.current.getElementsByTagName("tr");
      for (var i = 0; i < rows.length; i++) {
        const r = rows[i];
        if (r.className == "outterhheader" || r.className == "header") {
          const cells = r.getElementsByTagName("td");
          for (var ii = 0; ii < cells.length; ii++) {
            const cell = cells[ii];
            cell.style.backgroundColor = "var(--bs-gray-600)";
          }
        }
        if ((r.className == "altline" || r.className == "line") && r.lineinfo != "")
          r.ondblclick = function () {
            ShowRecord(this.getAttribute("lineinfo"));
          };
      }
    }
  }, [reportData]);

  const ShowRecord = (str) => {
    const obj = str.split(";")[0];
    const id = str.split(";")[str.split(";").length - 1];

    const state = store.getState();
    const root = getRootsByKey(state, obj);

    if (root) {
      const commandParams = {
        list: root?.list,
        form: root?.form,
        rj: root?.rj,
        AppId: process.env.REACT_APP_SOFTONE_APPID,
        autoLocate: true,
      };
      const value = {
        cmd: "EditMaster",
        text: root?.text,
        idx: `redirector_${tabID}_${obj}`,
        autoLocateID: id,
        command: root?.command ?? obj,
        commandParams,
        screenState: {},
        isWindow: popUpRedirection == 1,
        objectState: "view",
      };
      dispatch(
        setScreenFromJob({
          key: `redirector_${tabID}_${obj}`,
          value,
        })
      );
      dispatch(setNewTab(value));
    }
  };

  return (
    <>
      <div style={{ backgroundColor: "var(--bs-body-bg)", flex: 1, overflow: "auto" }}>
        {reportInfo?.success ? (
          reportInfo?.npages > 0 ? (
            reportData && (
              <div
                ref={reportRef}
                className="softone-report"
                dangerouslySetInnerHTML={{ __html: reportData?.data }}
                style={{
                  flex: 1,
                  overflow: "auto",
                  height: "100%",
                  width: "100%",
                }}
              />
            )
          ) : (
            <NoBrowserData />
          )
        ) : (
          <ErrorDisplay error={reportInfo?.error} />
        )}
      </div>
      {reportInfo?.success && reportData?.success && <ReportFooter tabID={tabID} />}
    </>
  );
};

export default Browser;
