import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import scrollgridPlugin from "@fullcalendar/scrollgrid";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import "@fullcalendar/core/locales/el";
import "bootstrap-icons/font/bootstrap-icons.css";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { useDispatch, useSelector } from "react-redux/es";
import {
  getAccesRights,
  getBrowserInfo,
  getCommandByIndex,
  getCommandParams,
  getFormDesign,
  getGroupData,
  getGroupFields,
  getScreenState,
  getSelectedRow,
  getTabIdx,
  getTableData,
} from "../../../../redux/selectors";
import { calendar } from "../../../../logic/calendar";
import setData from "../../../../services/setData";
import getBrowserInfoService from "../../../../services/getBrowserInfo";
import serviceGetFormDesign from "../../../../services/getFormDesign";
import {
  setBrowserInfo,
  setCalendarDates,
  setFormDesign,
  setGetData,
  setLoading,
  setScreenState,
  setSelectedRow,
} from "../../../../redux/features/ui/uiSlice";
import { setError } from "../../../../redux/features/modals/modalsSlice";
import getData from "../../../../services/getData";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/shift-away-extreme.css";
import DatePicker from "./DatePicker";
import { locateInfoF } from "../../../functions/locateInfo";

const Calendar = React.memo(({ tabID }) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.settings?.darkMode);
  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));
  const tableData = useSelector((state) => getTableData(state, tabID));
  const object = useSelector((state) => getCommandByIndex(state, tabID).split("&")[0]);
  const menuOpen = useSelector((state) => state.modals.menu);
  const accessRights = useSelector((state) => getAccesRights(state, tabID));
  const tabIDX = useSelector((state) => getTabIdx(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const commandParams = useSelector((state) => getCommandParams(state, tabID));
  const selectedRow = useSelector((state) => getSelectedRow(state, tabID));
  const settings = useSelector((state) => state.settings.app);
  const [calendarApi, setCalendarApi] = React.useState(null);
  const screenState = useSelector((state) => getScreenState(state, tabID));
  const currentTabID = useSelector((state) => state.ui.tabID);
  const groupData = useSelector((state) => getGroupData(state, tabID));
  const groupFields = useSelector((state) => getGroupFields(state, tabID));
  const defaultColor =
    settings.calendarColor.split(";").length == 6
      ? rgbToHex(softone2rgb(settings.calendarColor.split(";")[0]))
      : "#0d6efd";
  const defaultTextColor =
    settings.calendarColor.split(";").length == 6
      ? rgbToHex(softone2rgb(settings.calendarColor.split(";")[1]))
      : "white";

  const [events, setEvents] = React.useState([]);
  const [resources, setResources] = React.useState([]);

  const objectLogicData = calendar.objectFields?.[object]?.tableData;
  const fromDateField = objectLogicData?.fromDate;
  const toDateField = objectLogicData?.toDate;
  const allDayField = objectLogicData?.allDay;
  const titleField = objectLogicData?.title;
  const descriptionField = objectLogicData?.description;
  const canZone = settings.calendarColorZoneField != "" && settings.calendarColorZones.length > 0;
  const colorZones = {};

  const bgOpacity = darkMode == 1 ? 0.75 : 0.8;

  const [showPicker, setShowPicker] = React.useState(false);

  const [hiddenDays, setHiddenDays] = React.useState([]);
  if (settings.calendarColorZones.length > 0) {
    settings.calendarColorZones.map((item) => {
      var bgColorProp,
        bgColor,
        color,
        bold,
        italic,
        underline,
        strikeOut = undefined;
      if (item.COLOR.split(";").length == 6) {
        bgColorProp =
          item.COLOR.split(";")[0] > 0
            ? `rgb(${item.COLOR.split(";")[0] % 256},${(item.COLOR.split(";")[0] / 256) % 256},${
                (item.COLOR.split(";")[0] / 65536) % 256
              })`
            : defaultColor;
        bgColor =
          item.COLOR.split(";")[0] > 0
            ? `rgba(${item.COLOR.split(";")[0] % 256},${(item.COLOR.split(";")[0] / 256) % 256},${
                (item.COLOR.split(";")[0] / 65536) % 256
              },${bgOpacity})`
            : `rgba(${hexToRgb(defaultColor)},${bgOpacity})`;

        color =
          item.COLOR.split(";")[1] > 0
            ? `rgb(${item.COLOR.split(";")[1] % 256},${(item.COLOR.split(";")[1] / 256) % 256},${
                (item.COLOR.split(";")[1] / 65536) % 256
              })`
            : "black";

        bold = item.COLOR.split(";")[2];
        italic = item.COLOR.split(";")[3];
        underline = item.COLOR.split(";")[4];
        strikeOut = item.COLOR.split(";")[5];
      }

      colorZones[item.VALUE] = {
        bgColorProp,
        bgColor,
        color,
        bold,
        italic,
        underline,
        strikeOut,
      };
    });
  }

  React.useEffect(() => {
    if (settings.calendarBussinesDays.split(",").length > 0) {
      setHiddenDays([0, 1, 2, 3, 4, 5, 6, 7].filter((day) => !settings.calendarBussinesDays.includes(day)));
    }

    let intervalID;

    if (settings?.calendarRefreshRate > 0) {
      intervalID = setInterval(async () => {
        if (document.visibilityState === "visible") {
          const browserInfo = await getBrowserInfoService(tabID);
          dispatch(setBrowserInfo({ value: browserInfo, tabID }));
        }
      }, settings?.calendarRefreshRate * 1000); // Capture the interval ID
    }
    return () => {
      if (intervalID) clearInterval(intervalID);
    };
  }, []);

  React.useEffect(() => {
    if (browserInfo.success && browserInfo.totalcount > 0) {
      if (groupFields) setResources(groupData.map((r) => ({ id: r.code, title: r.name })));
      setEvents(
        tableData.map((col) => {
          const hasZoneField =
            canZone && col?.[`${object}_${settings.calendarColorZoneField}`]
              ? col?.[`${object}_${settings.calendarColorZoneField}`]
              : false;

          const colValue = hasZoneField ? hasZoneField.split("|")[0] : undefined;
          const backgroundColor = hasZoneField
            ? colorZones?.[colValue]?.bgColor ?? `rgba(${hexToRgb(defaultColor)},${bgOpacity})`
            : `rgba(${hexToRgb(defaultColor)},${bgOpacity})`;

          const textColor = hasZoneField ? colorZones?.[colValue]?.color ?? defaultTextColor : defaultTextColor;
          const className = [];
          var resourceId = undefined;
          if (col[`${object}_${groupFields?.[0]?.name}`])
            resourceId = col[`${object}_${groupFields[0].name}`].split("|")[0];

          const title = col[titleField] ?? col[calendar.objectFields[object].tableData.text] ?? "";
          const description = col[descriptionField] ?? undefined;

          hasZoneField && colorZones?.[colValue]?.bold == 1 && className.push("fc-event-bold");
          hasZoneField && colorZones?.[colValue]?.italic == 1 && className.push("fc-event-italic");
          hasZoneField && colorZones?.[colValue]?.underline == 1 && className.push("fc-event-underline");
          hasZoneField && colorZones?.[colValue]?.strikeOut == 1 && className.push("fc-event-strikeOut");

          const bgColorProp =
            hasZoneField && colorZones?.[colValue]?.bgColorProp
              ? hasZoneField && colorZones?.[colValue]?.bgColorProp
              : undefined;

          return {
            id: col.ZOOMINFO.split(";")[col.ZOOMINFO.split(";").length - 1],
            resourceId,
            title,
            start: col[fromDateField] ? new Date(col[fromDateField]).toISOString() : "",
            end: col[toDateField] ? new Date(col[toDateField]).toISOString() : "",
            backgroundColor,
            textColor,
            borderColor: backgroundColor,
            color: textColor,
            className,
            display: "block",
            allDay: col[allDayField] ? col[allDayField].split("|")[0] == 1 : false,
            extendedProps: {
              className,
              description,
              bgColor: bgColorProp,
            },
          };
        })
      );
    }
  }, [tableData]);

  React.useEffect(() => {
    if (currentTabID == tabID && calendarApi) calendarApi.updateSize();
  }, [currentTabID]);

  React.useEffect(() => {
    if (screenState == "browser" && calendarApi) calendarApi.updateSize();
  }, [screenState]);

  React.useEffect(() => {
    calendarApi && calendarApi.updateSize();
  }, [menuOpen, showPicker]);

  const [originalEvent, setOriginalEvent] = React.useState(null);

  const handleEventDragStart = (info) => {
    calendarApi.getEventById(info.event.id).setExtendedProp("_isDragging", true);
    const { start, end } = info.event;
    setOriginalEvent({ start, end });
  };

  const handleEventDragStop = (info) => {
    calendarApi.getEventById(info.event.id).setExtendedProp("_isDragging", false);
  };

  const handleEventDrop = async (info) => {
    calendarApi.getEventById(info.event.id).setExtendedProp("_isDragging", false);
    var { start, end, id, allDay } = info.event;
    const oldResource = info?.oldResource?.id;
    const newResource = info?.newResource?.id;
    const oldAllDay = info.oldEvent.allDay;
    if (allDay) {
      if (!end) {
        var hoursToMs = start.getTime() + 24 * 60 * 60 * 1000;
        end = new Date(start);
        end.setTime(hoursToMs);
      }
    }
    if (oldAllDay && !allDay) {
      var hoursToMs = start.getTime() + 60 * 60 * 1000;
      end = new Date(start);
      end.setTime(hoursToMs);
    }
    dispatch(setLoading({ show: true, tabID }));
    const data = {
      [calendar.objectFields[object].setDataTable]: [
        {
          [fromDateField.split("_")[1]]: formatDateTime(start),
          [toDateField.split("_")[1]]: formatDateTime(end),
          [allDayField.split("_")[1]]: allDay ? 1 : 0,
        },
      ],
    };

    if (oldResource != newResource && groupFields?.[0]?.name) {
      const resourceField = groupFields?.[0]?.name;
      data[calendar.objectFields[object].setDataTable][0][resourceField] = newResource;
    }

    const response = await setData({ tabID, data, key: id, defaultObject: object });
    if (response.success) {
      const indexToUpdate = events.findIndex((item) => item.id == id);
      const copyEvents = [...events];
      copyEvents[indexToUpdate] = {
        ...copyEvents[indexToUpdate],
        allDay,
        start: start.toISOString(),
        end: end.toISOString(),
      };
      if (oldResource != newResource && groupFields?.[0]?.name) {
        copyEvents[indexToUpdate] = {
          ...copyEvents[indexToUpdate],
          resourceId: newResource,
          [groupFields?.[0]?.name]: newResource,
        };
      }

      setEvents(copyEvents);
    } else {
      dispatch(setError({ message: response.error, show: true }));
      info.event.setDates(originalEvent.start, originalEvent.end);
      console.log(response);
    }
    dispatch(setLoading({ show: false, tabID }));
  };

  const openRecord = async (id) => {
    var locateinfo = "";
    var FormDesign = JSON.parse(JSON.stringify(formDesign ?? {}));
    var noError = true;
    const index = events.findIndex((item) => item.id == id);
    dispatch(setSelectedRow({ value: index, tabID }));
    if (browserInfo.browserOnly != true && commandParams.browseronly != 1) {
      if (accessRights?.browserOnly != true) {
        dispatch(setLoading({ show: true, tabID }));
        if (!FormDesign?.success) {
          const fetchFormDesign = await serviceGetFormDesign(tabID);
          if (fetchFormDesign.success) {
            dispatch(setFormDesign({ value: fetchFormDesign, idx: tabIDX, tabID }));
            dispatch(setScreenState({ value: "form", tabID }));

            FormDesign = fetchFormDesign;
          } else {
            noError = false;
            dispatch(setLoading({ show: false, tabID }));
            console.log(fetchFormDesign);
          }
        } else {
          dispatch(setScreenState({ value: "form", tabID }));
        }

        if (noError) {
          locateinfo = locateInfoF(FormDesign);

          const data = await getData({ key: id, locateinfo, tabID });
          if (data.success) {
            dispatch(setGetData({ value: data, tabID }));
            dispatch(setLoading({ show: false, tabID }));
          } else {
            dispatch(setLoading({ show: false, tabID }));
          }
        }
      }
    }
  };

  const handleEventClick = (arg) => {
    // if (doubleClickTimeoutRef.current) {
    //   // Double-click logic here
    openRecord(arg.event.id);
    //   clearTimeout(doubleClickTimeoutRef.current);
    //   doubleClickTimeoutRef.current = null;
    // } else {
    const index = events.findIndex((item) => item.id == arg.event.id);
    dispatch(setSelectedRow({ value: index, tabID }));
    //   // Single-click logic here
    //   doubleClickTimeoutRef.current = setTimeout(() => {
    //     clearTimeout(doubleClickTimeoutRef.current);
    //     doubleClickTimeoutRef.current = null;
    //   }, 300); // Adjust the timeout duration as needed
    // }
  };

  const handleCalendarRef = (ref) => {
    if (ref) {
      setCalendarApi(ref.getApi());
    }
  };

  const handleEventDidMount = (info) => {
    const viewsNot2RenderTooltip = ["listDay", "listMonth", "listWeek"];
    if (
      !info.isResizing &&
      !calendarApi.getEventById(info.event.id).extendedProps._isDragging &&
      viewsNot2RenderTooltip.indexOf(info?.view?.type) == -1
    ) {
      const title = `<div class="tooltip-title">
                        <span class="tooltip-record-color" style="background-color:${
                          info.event?.extendedProps?.bgColor ?? defaultColor ?? "var(--bs-primary)"
                        }"></span>
                        <strong>${info.event.title}</strong>
                      </div>
                      <hr>`;
      const desc = info.event?.extendedProps?.description
        ? `<div class="tooltip-description"><strong><span>${info.event?.extendedProps?.description}</span></strong></div><hr>`
        : "";

      const times = info.event.allDay
        ? `<div class="tooltip-times">Ολοήμερο</div>`
        : `<div class="tooltip-times">
              <strong>Έναρξη:</strong> <span>${formatDateForView(info.event.start)}</span>
              <strong>Λήξη: </strong> <span>${info.event.end && formatDateForView(info.event.end)}</span>
          </div>`;
      tippy(info.el, {
        content: `<div class="tooltip-content">
                ${title}
                ${desc}
                ${times}
              </div>`,
        theme: darkMode == 1 ? "dark" : "light",
        animation: "shift-away-extreme",
        allowHTML: true,
        placement: "left",
        maxWidth: 350,
        delay: [500, 0],
      });
    }

    if (info.view.type != "dayGridMonth") {
      info.el.style.border = 0;
      info.el.style.borderLeft = `0.25rem solid ${info.event?.extendedProps?.bgColor ?? defaultColor}`;
      info.el.style.paddingLeft = "0.25rem";

      if (info.event.textColor && viewsNot2RenderTooltip.indexOf(info?.view?.type) == -1) {
        info.el.style.color = info.event.textColor;
      }
    }
    if (info.event.classNames && info.event.classNames.length > 0) {
      const title = info.el.querySelectorAll(".fc-event-title");
      info.event.classNames.map((cl) => title.forEach((element) => element.classList.add(cl)));
    }
  };

  const buttonText = {
    today: "Σήμερα",
    month: "Μήνας",
    week: "Εβδομάδα",
    day: "Ημέρα",
    list: "Λίστα",
    year: "Έτος",
  };

  const headerToolbar = {
    start: "title",
    end: groupData
      ? "changeViewToResWorkWeek,resourceTimeGridWeek,resourceTimeGridDay changeView prev,today,next"
      : `bMonth,changeViewToWorkWeek,bWeek,bDay bList${showPicker ? "" : " prev,today,next"}`,
  };

  const businessHours = {
    daysOfWeek: settings.calendarBussinesDays.split(",").sort(),
    startTime: settings.calendarStart,
    endTime: settings.calendarEnd,
  };

  const views = {
    timeGridWeek: {
      titleFormat: { year: "numeric", month: "long" },
      dayHeaderFormat: { weekday: "short", day: "numeric" },
    },
    multiMonthYear: {
      dayHeaderFormat: { weekday: "short" },
      displayEventEnd: true,
      displayEventTime: true,
    },
    resourceTimelineDay: {
      titleFormat: { year: "numeric", month: "short", day: "numeric", weekday: "short" },
    },
    timeGridResWorkWeek: {
      type: "resourceTimeGridWeek",
      duration: { weeks: 1 },
      hiddenDays: hiddenDays,
    },
    timeGridWorkWeek: {
      type: "timeGridWeek",
      duration: { weeks: 1 },
      hiddenDays: hiddenDays,
    },
  };

  const customButtons = {
    changeView: {
      text: "Timeline",
      click: () => {
        calendarApi.changeView("resourceTimelineDay");
      },
    },
    changeViewToResWorkWeek: {
      text: "Εργ. Εβδομάδα",
      click: () => {
        calendarApi.changeView("timeGridResWorkWeek");
      },
    },
    changeViewToWorkWeek: {
      text: "Εργ. Εβδομάδα",
      click: () => {
        calendarApi.changeView("timeGridWorkWeek");
      },
    },

    bMonth: {
      text: "Μήνας",
      click: () => {
        calendarApi.changeView("dayGridMonth");
        setShowPicker(false);
      },
    },
    bWeek: {
      text: "Εβδομάδα",
      click: () => {
        calendarApi.changeView("timeGridWeek");
        setShowPicker(false);
      },
    },
    bDay: {
      text: "Ημέρα",
      click: () => {
        calendarApi.changeView("timeGridDay");
        setShowPicker(true);
      },
    },
    bList: {
      text: "Λίστα",
      click: () => {
        setShowPicker(false);
        if (calendarApi?.view?.type == "timeGridDay") return calendarApi.changeView("listDay");
        if (calendarApi?.view?.type == "timeGridWeek") return calendarApi.changeView("listWeek");
        if (calendarApi?.view?.type == "dayGridMonth") return calendarApi.changeView("listMonth");
      },
    },
  };

  const dayHeaderFormat = { weekday: "long" };

  return (
    <div className="browser-calendar w-100 h-100 p-2 d-flex">
      <FullCalendar
        dayHeaderClassNames="fc-custom-header-class"
        displayEventTime={false}
        schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_KEY}
        expandRows={true}
        height="100%"
        slotMinWidth={100}
        slotLabelFormat={[
          {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          },
        ]}
        plugins={[
          listPlugin,
          dayGridPlugin,
          timeGridPlugin,
          multiMonthPlugin,
          bootstrap5Plugin,
          bootstrapPlugin,
          interactionPlugin,
          resourceTimeGridPlugin,
          resourceTimelinePlugin,
          scrollgridPlugin,
        ]}
        // themeSystem={"bootstrap5"}
        customButtons={customButtons}
        initialView={groupData ? "resourceTimeGridDay" : "timeGridWeek"}
        resources={resources}
        ref={handleCalendarRef}
        firstDay={settings.calendarFirstDay}
        locale="el"
        eventTimeFormat={{
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
          trigger: "manual",
        }}
        dayCellDidMount={(info) => {
          if (info.resource) {
            info.el.style.backgroundColor = numberToRGBA(info.resource.id);
          }
        }}
        resourceLaneDidMount={(info) => {
          info.el.style.backgroundColor = numberToRGBA(info.resource.id);
        }}
        resourceAreaHeaderContent={groupFields?.[0]?.caption}
        nowIndicator={true}
        allDayText="Ολοήμερο"
        eventMaxStack={2}
        dayMaxEventRows={true}
        slotDuration={settings.calendarSlots}
        views={views}
        now={new Date()}
        dayHeaderFormat={dayHeaderFormat}
        headerToolbar={headerToolbar}
        buttonText={buttonText}
        businessHours={businessHours}
        editable={!(accessRights?.browserOnly == true || accessRights?.update == false)}
        selectable={!(accessRights?.browserOnly == true || accessRights?.create == false)}
        allDaySlot={true}
        defaultAllDayEventDuration={{ days: 1 }}
        events={events}
        eventDrop={handleEventDrop}
        eventResize={handleEventDrop}
        eventDragStart={handleEventDragStart}
        eventDragStop={handleEventDragStop}
        eventResizeStart={handleEventDragStart}
        eventResizeStop={handleEventDragStop}
        eventColor={defaultColor}
        dayMinWidth={50}
        select={(arg) => {
          dispatch(
            setCalendarDates({
              tabID,
              show: true,
              start: formatDateTime(arg.start),
              end: formatDateTime(arg.end),
              resourceId: arg?.resource?.id,
            })
          );
        }}
        eventDidMount={handleEventDidMount}
        unselectAuto={false}
        scrollTimeReset={false}
        scrollTime={settings.calendarStart ?? "09:00"}
        unselect={() =>
          dispatch(
            setCalendarDates({
              tabID,
              show: false,
              start: undefined,
              end: undefined,
              resourceId: undefined,
            })
          )
        }
        eventClick={handleEventClick}
        eventClassNames={(arg) => (arg.event.id === events?.[selectedRow]?.id ? "shadow calendar-selected-record" : "")}
      />
      {showPicker && <DatePicker events={events} calendarApi={calendarApi} />}
    </div>
  );
});

export default Calendar;

const formatDateForView = (date) =>
  [date.getDate().padLeft(), (date.getMonth() + 1).padLeft(), date.getFullYear()].join("/") +
  " " +
  [date.getHours().padLeft(), date.getMinutes().padLeft()].join(":");

const formatDateTime = (date) =>
  [date.getFullYear(), (date.getMonth() + 1).padLeft(), date.getDate().padLeft()].join("-") +
  " " +
  [date.getHours().padLeft(), date.getMinutes().padLeft(), date.getSeconds().padLeft()].join(":");

const componentToHex = (c) => {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
};
const rgbToHex = ({ r, g, b }) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

const softone2rgb = (code) => {
  const r = code % 256;
  const g = (code / 256) % 256;
  const b = (code / 65536) % 256;
  return { r: parseInt(r), g: parseInt(g), b: parseInt(b) };
};

const numberToRGBA = (number) => {
  // Ensure the number is positive
  const base = Math.abs(number * 10);

  // Use modulo to constrain the values to 0-255
  const r = base % 256; // Red
  const g = (base * 2) % 256; // Green
  const b = (base * 3) % 256; // Blue

  // Alpha can be between .5 and 1 for better visibility (adjust as needed)
  const a = 0.05;

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

function hexToRgb(hex) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return `${r}, ${g}, ${b}`;
}
