import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getFormDesign, getObjectState, getSelectedRow, getTableData } from "../../../../../redux/selectors";
import { setGetData, setLoading, setSelectedRow } from "../../../../../redux/features/ui/uiSlice";
import getData from "../../../../../services/getData";
import { locateInfoF } from "../../../../functions/locateInfo";
const NavigationArrow = ({ tabID }) => {
  const dispatch = useDispatch();

  const selectedRow = useSelector((state) => getSelectedRow(state, tabID));
  const tableData = useSelector((state) => getTableData(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));

  const handlePrevious = async () => {
    dispatch(setLoading({ show: true, tabID }));
    const id = tableData[selectedRow - 1].ZOOMINFO.substring(tableData[selectedRow - 1].ZOOMINFO.indexOf(";") + 1);
    var locateinfo = locateInfoF(formDesign);
    const data = await getData({ key: id, locateinfo, tabID });
    if (data.success) {
      dispatch(setGetData({ value: data, tabID }));
    } else {
      console.log(data);
    }
    dispatch(setSelectedRow({ tabID, value: selectedRow - 1 }));
    dispatch(setLoading({ show: false, tabID }));
  };

  const handleNext = async () => {
    dispatch(setLoading({ show: true, tabID }));
    const id = tableData[selectedRow + 1].ZOOMINFO.substring(tableData[selectedRow + 1].ZOOMINFO.indexOf(";") + 1);
    var locateinfo = locateInfoF(formDesign);
    const data = await getData({ key: id, locateinfo, tabID });
    if (data.success) {
      dispatch(setGetData({ value: data, tabID }));
    } else {
      console.log(data);
    }
    dispatch(setSelectedRow({ tabID, value: selectedRow + 1 }));
    dispatch(setLoading({ show: false, tabID }));
  };

  return (
    <React.Fragment>
      <div
        className="d-flex align-items-center justify-content-start"
        onClick={() => objectState == "view" && selectedRow > 0 && handlePrevious()}
        style={{
          userSelect: "none",
          cursor: objectState == "view" && selectedRow > 0 ? "pointer" : "default",
          opacity: objectState == "view" && selectedRow > 0 ? 1 : 0.5,
        }}
      >
        <IoIosArrowBack size="1rem" />
      </div>
      <div
        className="d-flex align-items-center justify-content-start"
        onClick={() => objectState == "view" && tableData && selectedRow < tableData.length - 1 && handleNext()}
        style={{
          userSelect: "none",
          cursor: objectState == "view" && tableData && selectedRow < tableData.length - 1 ? "pointer" : "default",
          opacity: objectState == "view" && tableData && selectedRow < tableData.length - 1 ? 1 : 0.5,
        }}
      >
        <IoIosArrowForward size="1rem" />
      </div>
    </React.Fragment>
  );
};

export default NavigationArrow;
