import React from "react";
import { BsCalendar3 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux/es";
import { getBrowserView, getCommandByIndex, getTableColumns } from "../../../../../redux/selectors";
import { setBrowserInfo, setBrowserView, setLoading } from "../../../../../redux/features/ui/uiSlice";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { calendar } from "../../../../../logic/calendar";
import getBrowserInfo from "../../../../../services/getBrowserInfo";

const Calendar = ({ tabID }) => {
  const dispatch = useDispatch();
  const tableColumns = useSelector((state) => getTableColumns(state, tabID));
  const object = useSelector((state) => getCommandByIndex(state, tabID).split("&")[0]);
  const browserView = useSelector((state) => getBrowserView(state, tabID));

  const fromDateIndex = tableColumns.findIndex(
    (column) => column.dataIndex == calendar.objectFields?.[object]?.fromDate
  );
  const toDateIndex = tableColumns.findIndex((column) => column.dataIndex == calendar.objectFields?.[object]?.toDate);

  const [canCalendar, setCanCalendar] = React.useState(fromDateIndex !== -1 && toDateIndex !== -1);

  React.useEffect(() => {
    setCanCalendar(fromDateIndex !== -1 && toDateIndex !== -1);
  }, []);

  const handleClick = async () => {
    if (browserView == "browser") {
      dispatch(setBrowserView({ tabID, value: "calendar" }));
    } else {
      dispatch(setLoading({ show: true, tabID }));
      const response = await getBrowserInfo(tabID);
      dispatch(setBrowserInfo({ value: response, tabID }));
      dispatch(setBrowserView({ tabID, value: "browser" }));
      dispatch(setLoading({ show: false, tabID }));
    }
  };
  return (
    <React.Fragment>
      {canCalendar && (
        <div
          className="d-flex align-items-center justify-content-start"
          onClick={handleClick}
          style={{
            userSelect: "none",
            cursor: "pointer",
          }}
        >
          {browserView == "browser" ? <BsCalendar3 size="1rem" /> : <AiOutlineUnorderedList />}
        </div>
      )}
    </React.Fragment>
  );
};

export default Calendar;
