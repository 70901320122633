import React from "react";
import { FiSave } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { getBuffData, getFormDesign, getNewData, getObjectState } from "../../../../../redux/selectors";
import setData from "../../../../../services/setData";
import serviceGetBrowserInfo from "../../../../../services/getBrowserInfo";
import {
  setBrowserInfo,
  setGetData,
  setLoading,
  setObjectEvent,
  setObjectState,
} from "../../../../../redux/features/ui/uiSlice";
import { setError, setMessage } from "../../../../../redux/features/modals/modalsSlice";
import { locateInfoF } from "../../../../functions/locateInfo";

const SaveRecord = ({ tabID }) => {
  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const newData = useSelector((state) => getNewData(state, tabID));
  const buffData = useSelector((state) => getBuffData(state, tabID));

  const handleSave = async () => {
    dispatch(setLoading({ show: true, tabID }));
    const key = formDesign.key;
    const tableName = key.split(".")[0];
    const fieldName = key.split(".")[1];
    const keyValue = buffData?.[tableName]?.[0]?.[fieldName] ?? "";
    var locateinfo = locateInfoF(formDesign);
    const data = await setData({
      key: objectState == "new" ? "" : keyValue ?? "",
      data: newData,
      locateinfo,
      tabID,
    });

    if (data.success) {
      const browser = await serviceGetBrowserInfo(tabID);
      dispatch(setBrowserInfo({ tabID, value: browser }));
      await dispatch(setObjectEvent({ tabID, value: "AFTER_POST" }));
      dispatch(setGetData({ value: data, tabID }));
      dispatch(setObjectState({ value: "view", tabID }));
      if (data.message) {
        dispatch(setMessage({ show: true, message: data.message }));
      }
    } else {
      console.log(data);
      dispatch(setError({ show: true, message: data.error }));
    }

    dispatch(setLoading({ show: false, tabID }));
  };

  return (
    <div
      className="d-flex align-items-center justify-content-start"
      onClick={() => objectState != "view" && handleSave()}
      style={{
        userSelect: "none",
        cursor: objectState != "view" ? "pointer" : "default",
        opacity: objectState != "view" ? 1 : 0.5,
      }}
    >
      <FiSave size="1rem" className="me-1" />
      Καταχώρηση
    </div>
  );
};

export default SaveRecord;
