//------------------------------------------- GENERAL ------------------------------------------------
export const getSN = (state) => state.systemParams?.serialnumber;
export const getClientID = (state) => state.session?.clientID;
export const getURL = (state) => state.session?.url;
export const getOutputFormats = (state) => state.systemParams?.outputformats;

export const getCurrentTabIndex = (state) => state.ui.tabID;
export const getTabsOrder = (state) => state.ui.order;
export const getTabs = (state) => state.ui.tabs;
export const getCurrentTab = (state, tabID) => state.ui.tabs[tabID];
export const getPreviousScreenState = (state, tabID) => state.ui.tabs[tabID].screenState.past;
export const getScreenState = (state, tabID) => state.ui.tabs[tabID].screenState.present;
export const getObjectState = (state, tabID) => state.ui.tabs[tabID]?.objectState;
export const getObjectEvent = (state, tabID) => state.ui.tabs[tabID]?.objectEvent;
export const getEventCounter = (state, tabID) => state.ui.tabs[tabID]?.eventCounter;

export const getCommand = (state) => state.ui.tabs[getCurrentTabIndex(state)]?.command;
export const getCommandByIndex = (state, tabID) => state.ui.tabs[tabID].command;
export const getTabIdx = (state, tabID) => state.ui.tabs?.[tabID]?.idx;

export const getAccesRights = (state, tabID) => state.screens.screens[getTabIdx(state, tabID)]?.accessRights;
export const getLastSelections = (state, tabID) => state.screens.screens[getTabIdx(state, tabID)]?.lastSelections;

export const getDialog = (state, tabID) => state.ui.tabs[tabID]?.dialog;
export const getFilters = (state, tabID) => state.ui.tabs[tabID]?.dialog?.filters;

export const getBrowserInfo = (state, tabID) => state.ui.tabs[tabID]?.browserInfo;
export const getBrowserInfoFields = (state, tabID) => state.ui.tabs[tabID]?.browserInfo?.fields;
export const getTableData = (state, tabID) => state.ui.tabs[tabID]?.tableData;
export const getTableBrowserData = (state, tabID) => state.ui.tabs[tabID]?.tableBrowserData;
export const getTableColumns = (state, tabID) => state.ui.tabs[tabID]?.tableColumns;
export const getHiddenColumns = (state, tabID) => state.ui.tabs[tabID]?.hiddenColumns;
export const getColumnWidths = (state, tabID) => state.ui.tabs[tabID]?.columnWidths;
export const getSelectedRow = (state, tabID) => state.ui.tabs[tabID]?.browserData?.selectedRow;
export const getSortField = (state, tabID) => state.ui.tabs[tabID]?.dialog?.filters?.sort;
export const getBrowserView = (state, tabID) => state.ui.tabs[tabID]?.browserView;
export const getCanCalendar = (state, tabID) => state.ui.tabs[tabID]?.canCalendar;
export const gethasGroups = (state, tabID) => state.ui.tabs[tabID]?.group?.hasGroups;
export const getgroupToogle = (state, tabID) => state.ui.tabs[tabID]?.group?.toogle;
export const getGroupFields = (state, tabID) => state.ui.tabs[tabID]?.group?.filters?.fields;
export const getGroupData = (state, tabID) => state.ui.tabs[tabID]?.group?.filters?.data;

export const getFlexLayout = (state, tabID) => state.ui.tabs[tabID]?.flexLayout;
export const getResetLayout = (state, tabID) => state.ui.tabs[tabID]?.resetColumnWidths;

export const getCommandParams = (state, tabID) => state.ui.tabs[tabID].commandParams;

export const getRelJobMenu = (state, tabID) => state.ui.tabs[tabID].relJobMenu;
export const getCustomRelJobs = (state, tabID) => state.ui.tabs[tabID]?.customRelJobs;
export const getReportInfo = (state, tabID) => state.ui.tabs[tabID]?.reportInfo;
export const getReportData = (state, tabID) => state.ui.tabs[tabID]?.reportData;

export const getFormDesign = (state, tabID) => state.screens?.screens?.[getTabIdx(state, tabID)]?.formDesign;

export const getBuffData = (state, tabID) => state.ui.tabs[tabID]?.buffData;
export const getNewData = (state, tabID) => state.ui.tabs[tabID]?.newData;
export const getData = (state, tabID) => state.ui.tabs[tabID]?.getData?.data;

export const getDataCaption = (state, tabID) => state.ui.tabs[tabID]?.getData?.caption;

export const getGridByName = (state, tabID, name) => state.ui.tabs[tabID]?.grids?.[name];
export const getGridHiddenColumns = (state, tabID, name) => state.ui.tabs[tabID]?.grids?.[name]?.hiddenColumns;
export const getCurrentGridLine = (state, tabID, name) => state.ui.tabs[tabID]?.grids?.[name]?.currentLine;
export const getLinesCount = (state, tabID, name) => {
  switch (getObjectState(state, tabID)) {
    case "view":
      if (getData(state, tabID)?.[name])
        return getData(state, tabID)?.[name].reduce((max, item) => {
          return parseInt(item.LINENUM) > max ? parseInt(item.LINENUM) : max;
        }, 0);
      else return 0;
    case "edit":
      if (getBuffData(state, tabID)?.[name])
        return getBuffData(state, tabID)?.[name].reduce((max, item) => {
          return parseInt(item.LINENUM) > max ? parseInt(item.LINENUM) : max;
        }, 0);
      else return 0;
    case "new":
      if (getNewData(state, tabID)?.[name])
        return getNewData(state, tabID)?.[name].reduce((max, item) => {
          return parseInt(item.LINENUM) > max ? parseInt(item.LINENUM) : max;
        }, 0);
      else return 0;
    default:
      break;
  }
};

export const getRecData = (state, tabID) => {
  switch (getObjectState(state, tabID)) {
    case "view":
      return getData(state, tabID);
    case "edit":
    case "new":
      return getBuffData(state, tabID);
    // return getNewData(state, tabID);
    default:
      return {};
  }
};

export const getCalc = (state, tabID) => state.ui.tabs[tabID]?.shouldCalc;

export const getGadgetsParams = (state) => state.portal.gadgetsParams;
export const getGadgets = (state) => state.portal.gadgets;
export const getGadget = (state, id) => state.portal.gadgets?.[id];
export const getGadgetData = (state, id) => state.portal.gadgets?.[id]?.data;
export const getGadgetConfig = (state, id) => state.portal.gadgets?.[id]?.config;
export const getGadgetCollapsed = (state, id) => state.portal.gadgets?.[id].collapsed;
export const getGadgetCategory = (state, id) => state.portal.gadgets?.[id]?.category;
export const getGadgetBrowserInfo = (state, id) => state.portal.gadgets?.[id].browserInfo;
export const getGadgetTableColumns = (state, id) => state.portal.gadgets?.[id]?.tableColumns;
export const getGadgetHiddenColumns = (state, id) => state.portal.gadgets?.[id]?.hiddenColumns;
export const getGadgetTableData = (state, id) => state.portal.gadgets?.[id]?.tableData;
export const getGadgetTSortField = (state, id) => state.portal.gadgets?.[id]?.sort;
export const getGadgetTLoading = (state, id) => state.portal.gadgets?.[id]?.loading;
export const getGadgetFullScreen = (state, id) => state.portal.fullWindow;
export const getGadgetSelectedRow = (state, id) => state.portal.gadgets?.[id]?.selectedRow;

export const getCalendarProps = (state, tabID) => state.ui.tabs[tabID]?.calendar;
