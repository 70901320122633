import React from "react";
import { FiRefreshCw } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { getFormDesign, getObjectState, getData } from "../../../../../redux/selectors";
import { setGetData, setLoading, setObjectEvent } from "../../../../../redux/features/ui/uiSlice";
import { setError, setMessage } from "../../../../../redux/features/modals/modalsSlice";
import getDataService from "../../../../../services/getData";
import { locateInfoF } from "../../../../functions/locateInfo";

const Refresh = ({ tabID }) => {
  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const data = useSelector((state) => getData(state, tabID));

  const handleRefresh = async () => {
    dispatch(setLoading({ show: true, tabID }));
    const key = formDesign.key;
    const tableName = key.split(".")[0];
    const fieldName = key.split(".")[1];
    const keyValue = data?.[tableName]?.[0]?.[fieldName] ?? "";
    var locateinfo = locateInfoF(formDesign);
    const dataS = await getDataService({ key: keyValue, locateinfo, tabID });
    if (dataS.success) {
      dispatch(setGetData({ value: dataS, tabID }));
      await dispatch(setObjectEvent({ tabID, value: "AFTER_LOCATE" }));
      if (dataS.message) {
        dispatch(setMessage({ show: true, message: dataS.message }));
      }
    } else {
      dispatch(setError({ show: true, message: dataS.error }));
    }
    dispatch(setLoading({ show: false, tabID }));
  };

  return (
    <div
      className="d-flex align-items-center justify-content-start"
      onClick={() => objectState == "view" && handleRefresh()}
      style={{
        userSelect: "none",
        cursor: objectState == "view" ? "pointer" : "default",
        opacity: objectState == "view" ? 1 : 0.5,
      }}
    >
      <FiRefreshCw size="1rem" className="me-1" />
    </div>
  );
};

export default Refresh;
