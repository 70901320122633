import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccesRights, getFilters, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import { TiDeleteOutline } from "react-icons/ti";
import { BsCalendar3 } from "react-icons/bs";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";
import { calculate } from "../../../services/calculate";

const S1CalendarDate = ({ element, screenState, tabID, grid }) => {
  const isDateTime = element.dataType == "DateTime";
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const accessRights = useSelector((state) => getAccesRights(state, tabID));

  const currentGridLine = grid?.currentLine;

  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const model = screenState == "form" && formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0];
  const data = useSelector((state) => getRecData(state, tabID));

  const dateRef = React.useRef(null);
  const contRef = React.useRef(null);

  const [clear, showClear] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [viewValue, setViewValue] = React.useState("");

  const handleCalculate = async (value) => {
    if (grid) {
      dispatch(setGridChange({ tabID, name: grid.model, key, value }));
    } else {
      if (objectState == "view") {
        await dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ tabID, value: "edit" }));
      }
      dispatch(setChange({ tabID, table, key, value }));
      model?.updates && (await calculate(tabID, objectState));
    }
  };

  const handleClear = async () => {
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else {
      if (objectState == "view" && !grid) {
        await dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ tabID, value: "edit" }));
      }
      handleCalculate("");
    }
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(element.value);
      dispatch(setFilter({ tabID, table, key, value: element.value }));
    }
  }, [screenState]);

  React.useEffect(() => {
    if (screenState == "form" && !grid) {
      if (data?.[table]?.[0]?.[key] && data?.[table]?.[0]?.[key] != "") {
        setValue(dateConverterSINGLE(data?.[table]?.[0]?.[key]));
        setViewValue(displayConverter(data?.[table]?.[0]?.[key]));
      } else {
        setValue("");
        setViewValue("");
      }
    }
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    if (screenState == "form" && grid) {
      if (currentGridLine?.line?.[key] && currentGridLine?.line?.[key] != "") {
        setValue(dateConverterSINGLE(currentGridLine?.line?.[key]));
        setViewValue(displayConverter(currentGridLine?.line?.[key]));
      } else {
        setValue("");
        setViewValue("");
      }
    }
  }, [currentGridLine?.line?.[key]]);

  React.useEffect(() => {
    if (screenState == "dialog") {
      if (filters?.data?.[table]?.[key] && filters?.data?.[table]?.[key] != "") {
        setValue(dateConverterSINGLE(filters?.data?.[table]?.[key]));
        setViewValue(displayConverter(filters?.data?.[table]?.[key]));
      } else {
        setValue("");
        setViewValue("");
      }
    }
  }, [filters?.data?.[table]?.[key]]);

  const handleChange = (value) => {
    if (screenState == "dialog") {
      dispatch(
        setFilter({ tabID, table, key, value: isDateTime ? dateTimeConverter(value) : dateConverterSINGLE(value) })
      );
    } else if (screenState == "form") {
      handleCalculate(isDateTime ? dateTimeConverter(value) : dateConverterSINGLE(value));
    }
  };

  const handleInputChange = (e) => {
    let val = e.target.value.replace(/[^\d/]/g, "");
    const parts = val.split("/");
    let day, month;
    if (parts.length > 0) {
      day = checkDay(parts[0]);
      if (!day.success) return;
      val = day.value;
    }

    if (parts.length > 1) {
      day = checkDay(parts[0]);
      month = checkMonth(parts[1]);
      if (!month.success) return;
      val = `${day.value}${month.value}`;
    }

    if (parts.length > 2) {
      day = checkDay(parts[0]);
      month = checkMonth(parts[1]);
      if (!day.success) return;
      if (!month.success) return;
      val = `${parts[0]}/${parts[1]}/${parts[2]}`;
    }

    setViewValue(val);
  };

  const handleBlur = async () => {
    setIsFocused(false);
    let val;
    const today = new Date();
    let parts = viewValue.split("/");
    if (parts[0] > 0) {
      parts[0] = parts[0].padStart(2, "0");

      if (parts.length > 1) {
        parts[1] = parts[1] > 0 ? parts[1].padStart(2, "0") : (today.getMonth() + 1).toString().padStart(2, "0");
      } else {
        parts[1] = (today.getMonth() + 1).toString().padStart(2, "0");
      }

      if (parts.length > 2) {
        if (parts[2] == "") {
          parts[2] = today.getFullYear();
        } else if (parts[2] < 100) {
          parts[2] = 2000 + Number(parts[2]);
        } else {
          parts[2] = `0000${Number(parts[2])}`.slice(-4);
        }
      } else {
        parts[2] = today.getFullYear();
      }

      val = `${parts[0]}/${parts[1]}/${parts[2]}`;

      if (screenState == "dialog") {
        setViewValue(isValidDate(val) ? val : "");
        dispatch(
          setFilter({
            tabID,
            table,
            key,
            value: isValidDate(val) ? dateConverter(parts[2], parts[1] - 1, parts[0]) : "",
          })
        );
      } else if (screenState == "form") {
        if (objectState == "view") {
          await dispatch(changeToBuffer({ tabID }));
          dispatch(setObjectState({ tabID, value: "edit" }));
        }
        handleCalculate(val ? dateConverter(parts[2], parts[1] - 1, parts[0]) : "");
      }
      setViewValue(val);
    } else {
      if (screenState == "dialog") {
        setViewValue("");
        dispatch(
          setFilter({
            tabID,
            table,
            key,
            value: "",
          })
        );
      } else if (screenState == "form") {
        if (objectState == "view") {
          await dispatch(changeToBuffer({ tabID }));
          dispatch(setObjectState({ tabID, value: "edit" }));
        }
        handleCalculate("");
      }

      setValue("");
    }
  };

  return (
    <div
      className="s1calendardate w-100"
      style={{
        flex: `1 1 ${element.flex * 100}%`,
        overflow: "hidden",
        visibility: element.visible ? "visible" : "hidden",
      }}
    >
      <div className="inputGroup">
        {element.caption != "" && (
          <label className="text-truncate" htmlFor={element.index}>
            {`${element.caption}:`}
          </label>
        )}
        <div
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}${
            element.required && value == "" ? " required" : ""
          }`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
          role="button"
        >
          <div
            className="d-flex w-100"
            ref={contRef}
            onMouseEnter={() => showClear(true)}
            onMouseLeave={() => showClear(false)}
          >
            <input
              ref={dateRef}
              type="text"
              className={`w-100${value != "" ? " date-input-has-value" : ""}${
                element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)
                  ? " disabled"
                  : ""
              }`}
              disabled={element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)}
              readOnly={element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)}
              value={viewValue}
              name={element.name}
              onChange={(e) => !element.readOnly && handleInputChange(e)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => handleBlur(false)}
              autoComplete="off"
            />
            {value != "" && clear && !element.readOnly && (
              <div onClick={handleClear}>
                <TiDeleteOutline size="30px" role="button" />
              </div>
            )}
          </div>
          {!(element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)) && (
            <div
              style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
              onClick={() => {
                dateRef.current.showPicker();
                dateRef.current.click();
                dateRef.current.focus();
              }}
            >
              <BsCalendar3
                className={
                  element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)
                    ? "disabled"
                    : ""
                }
                color="rgb(64, 180, 252)"
                size="20px"
              />
            </div>
          )}
        </div>
      </div>
      <input
        type="date"
        ref={dateRef}
        value={value}
        className="text-truncate"
        onBlur={() => setIsFocused(false)}
        disabled={element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)}
        onChange={(e) => !element.readOnly && handleChange(e.target.value)}
        style={{
          pointerEvents: "none",
          position: "fixed",
          top: contRef?.current?.getBoundingClientRect()?.bottom,
          maxWidth: 0,
          maxHeight: 0,
          backgroundColor: "transparent",
          border: 0,
          color: "transparent",
        }}
      />
    </div>
  );
};

export default S1CalendarDate;

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

const dateConverter = (year, month, day) => {
  if (year != "") {
    var d = new Date(year, month, day);
    return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-");
  } else {
    return "";
  }
};

const dateConverterSINGLE = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-");
  } else {
    return value;
  }
};

const dateTimeConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return `${[d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-")} 00:00:00`;
  } else {
    return value;
  }
};

const displayConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/");
  } else {
    return value;
  }
};

const checkDay = (day) => {
  if (day > 31) {
    return { success: false };
  } else if (day > 3 || day.length == 2) {
    return { success: true, value: `${day}/` };
  } else {
    return { success: true, value: `${day}` };
  }
};

const checkMonth = (month) => {
  if (month > 12) {
    return { success: false };
  } else if ((month > 1 && month <= 12) || month.length == 2) {
    return { success: true, value: `${month}/` };
  } else {
    return { success: true, value: `${month}` };
  }
};

const isValidDate = (val) => {
  const dateTimePattern = /^\d{2}\/\d{2}\/\d{4}$/;
  console.log(val);
  if (!dateTimePattern.test(val)) return false;

  const [day, month, year] = val.split("/").map(Number);
  const date = new Date(year, month - 1, day);

  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
};
