import React from "react";
import { useDispatch, useSelector } from "react-redux/es";
import {
  getBrowserInfoFields,
  getGroupData,
  getGroupFields,
  getTableBrowserData,
  getgroupToogle,
} from "../../../../../redux/selectors";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { setTableData } from "../../../../../redux/features/ui/uiSlice";

const GroupBar = ({ tabID }) => {
  const dispatch = useDispatch();
  const menuOpen = useSelector((state) => getgroupToogle(state, tabID));
  const groupFields = useSelector((state) => getGroupFields(state, tabID));
  const groupData = useSelector((state) => getGroupData(state, tabID));
  const browserInfoFields = useSelector((state) => getBrowserInfoFields(state, tabID));

  const tableBrowserData = useSelector((state) => getTableBrowserData(state, tabID));

  const [buttonHovered, setButtonHovered] = React.useState(false);

  const [currentGroups, setCurrentGroups] = React.useState(groupData);
  const [previousStack, setPreviousStack] = React.useState([]);
  const [groupLevel, setGroupLevel] = React.useState(0);
  const [groupLevelFilters, setGroupLevelFilters] = React.useState([]);

  const [title, setTile] = React.useState([]);

  React.useEffect(() => {
    setCurrentGroups(groupData);
  }, [groupData]);

  React.useEffect(() => {
    setPreviousStack([]);
    setGroupLevel(0);
    setGroupLevelFilters([]);
    setTile([]);
  }, [tableBrowserData]);

  const handleRef = React.useRef(null);
  const sideBarRef = React.useRef(null);
  const [width, setWidth] = React.useState(320);

  const clickOnGroup = (data, item) => {
    if (item.items.length > 0) {
      previousStack.push(data);
      setPreviousStack(previousStack);
      setCurrentGroups(item.items);
      setTile((prevTitle) => [...prevTitle, item.name]);
    }
    if (groupLevel < groupFields.length - 1) {
      groupLevel < groupFields.length && setGroupLevel((x) => x + 1);
      groupLevelFilters.splice(groupLevel);
      groupLevelFilters.push(item.code);
    } else {
      groupLevelFilters[groupFields.length - 1] = item.code;
    }
    setGroupLevelFilters(groupLevelFilters);
    const fieldToFilter = [];
    groupLevelFilters.map((value, index) => {
      const browserIndex = browserInfoFields.findIndex(
        (el) => el.name.split(".").length && el.name.split(".")[1] == groupFields[index].name
      );

      fieldToFilter.push({
        [browserInfoFields[browserIndex].name.replace(".", "_")]: { value, type: browserInfoFields[browserIndex].type },
      });
    });

    // console.log(fieldToFilter);

    const filteredData = tableBrowserData.filter((item) => {
      return fieldToFilter.every((filter) => {
        const [filterKey, filterValue] = Object.entries(filter)[0];
        return (
          formatValues(item[filterKey], filterValue.type, 1) == formatValues(filterValue.value, filterValue.type, 2)
        );
      });
    });
    // console.log(filteredData);

    dispatch(setTableData({ tabID, value: filteredData }));
  };

  const resize = (e) => {
    let width = e.clientX - sideBarRef.current.getBoundingClientRect().left;
    if (width < 51) width = 53;
    setWidth(width);
  };

  const stopResize = (e) => {
    window.removeEventListener("mousemove", resize);
    window.removeEventListener("mouseup", stopResize);
  };

  const initResize = () => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResize);
  };

  return (
    <div
      className={`groupBar${!menuOpen ? " text-light" : ""}`}
      style={{
        width: menuOpen ? width : 53,
        background: !menuOpen ? "var(--bs-gray-dark)" : undefined,
      }}
      ref={sideBarRef}
    >
      {menuOpen ? (
        <div className="groupBar-handle" ref={handleRef} onMouseDown={initResize} />
      ) : (
        <div className="groupBar-handle-closed" />
      )}
      {menuOpen ? (
        <div className="w-100 d-flex flex-column">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: 40,
              minHeight: 40,
              background: "var(--bs-gray-dark)",
            }}
          >
            <div
              className="p-1 ms-1"
              style={{
                cursor: !previousStack.length ? "default" : "pointer",
                opacity: !previousStack.length ? 0.5 : 1,
                background: previousStack.length && buttonHovered ? "rgb(64, 180, 252)" : undefined,
              }}
              onMouseEnter={() => setButtonHovered(true)}
              onMouseLeave={() => setButtonHovered(false)}
              onClick={() => {
                if (previousStack.length) {
                  const prevState = previousStack.pop();
                  setPreviousStack(previousStack);
                  setCurrentGroups(prevState);
                  setGroupLevel((x) => x - 1);
                  setTile((prevTitle) => {
                    const newTitle = [...prevTitle];
                    newTitle.pop();
                    return newTitle;
                  });
                }
              }}
            >
              <IoIosArrowBack
                size="1rem"
                color={previousStack.length && buttonHovered ? "var(--bs-light)" : "rgb(64, 180, 252)"}
              />
            </div>
            {title.length > 0 && (
              <span className="ms-1 text-light d-flex align-items-center text-truncate">{title.join("/")}</span>
            )}
          </div>
          <div className="group-fields d-flex flex-column overflow-auto">
            {currentGroups.map((item, index) => (
              <div
                key={index}
                className={`group-by-item${groupLevelFilters[groupLevel] == item.code ? " selected" : ""}`}
                onClick={() => clickOnGroup(currentGroups, item)}
              >
                <span className="text-truncate w-100">{item.name}</span>
                <span style={{ width: 50, textAlign: "left" }}>{item.count}</span>
                {item.items.length > 0 && (
                  <span>
                    <IoIosArrowForward size="1rem" color="rgb(64, 180, 252)" />
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center mt-4 w-100">
          <span
            style={{
              writingMode: "vertical-rl",
              fontSize: "1.2rem",
            }}
          >
            Ομαδοποίηση
          </span>
        </div>
      )}
    </div>
  );
};

export default GroupBar;

const formatValues = (value, type, from) => {
  switch (type) {
    case "string":
      return value.split("|")[0];
    case "date":
      if (from == 1) {
        const date = new Date(value);
        return formatDate(date);
      } else {
        const date1Arr = value.split("/");
        const date = new Date(`${date1Arr[2]}-${date1Arr[1]}-${date1Arr[0]}`);
        return formatDate(date);
      }
    default:
      return value;
  }
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return (
    [date.getDate().padLeft(), (date.getMonth() + 1).padLeft(), date.getFullYear()].join("/") +
    " " +
    [date.getHours().padLeft(), date.getMinutes().padLeft(), date.getSeconds().padLeft()].join(":")
  );
};
