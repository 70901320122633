import React from "react";
import { BsChevronDown } from "react-icons/bs";
import { calendar } from "../../../logic/calendar";
import { set } from "../../../redux/features/settings/settingsSlice";
import { useDispatch, useSelector } from "react-redux/es";

const FirstDay = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.app);

  const boundRef = React.useRef(null);
  const pickerRef = React.useRef(null);

  const [isFocused, setIsFocused] = React.useState(false);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = React.useState(
    settings.calendarFirstDay ? calendar.settings.daysOfWeek[settings.calendarFirstDay] : ""
  );
  const [selectedRow, setSelectedRow] = React.useState(settings.calendarFirstDay);

  React.useEffect(() => {
    if (openPicker) {
      const clickListener = (e) => {
        const click = pickerRef.current.contains(e.target) || boundRef.current.contains(e.target);
        if (!click) {
          setIsFocused(false);
          setOpenPicker(false);
        }
      };
      const selectedDiv = pickerRef.current.querySelector(".input-picker-table-row-selected");
      if (selectedDiv) {
        selectedDiv.scrollIntoView({ block: "center" });
      }

      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [openPicker]);

  const handlePicker = (e) => {
    e.stopPropagation();
    setOpenPicker((x) => !x);
    setIsFocused((x) => !x);
  };

  const handleClickOnRow = (key) => {
    setSelectedRow(key);
    setValue(calendar.settings.daysOfWeek[key]);
    setOpenPicker(false);
    setIsFocused(false);
    dispatch(set({ key: "calendarFirstDay", value: key }));
  };
  return (
    <div className="custom w-100" style={{ flex: `1 1 100%`, overflow: "hidden" }}>
      <div className="inputGroup">
        <label className="text-truncate">Πρώτη ημέρα:</label>
        <div
          ref={boundRef}
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
          role="button"
        >
          <div className="d-flex w-100">
            <input
              role="button"
              style={{ backgroundColor: "transparent" }}
              className={`w-100$`}
              readOnly={true}
              value={value}
              onClick={handlePicker}
            />
          </div>
          <div
            style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
            role="button"
            onClick={handlePicker}
          >
            <BsChevronDown color="rgb(64, 180, 252)" size="20px" />
          </div>
        </div>
      </div>
      {openPicker && (
        <div
          ref={pickerRef}
          className="input-picker-div"
          style={{
            left:
              boundRef.current.getBoundingClientRect().left + 400 > window.innerWidth
                ? boundRef.current.getBoundingClientRect().right - 400
                : boundRef.current.getBoundingClientRect().left,
            width: boundRef.current.getBoundingClientRect().width,
            top:
              boundRef.current.getBoundingClientRect().bottom -
              (boundRef.current.getBoundingClientRect().bottom + 200 > window.innerHeight
                ? 200 + boundRef.current.getBoundingClientRect().height
                : 0),
            height: "200px",
            overflow: "auto",
          }}
        >
          <table className="input-picker-table w-100">
            <tbody>
              {Object.keys(calendar.settings.daysOfWeek).map((key) => {
                return (
                  <tr
                    key={key}
                    className={`input-picker-table-row${selectedRow == key ? " input-picker-table-row-selected" : ""}`}
                    onClick={() => handleClickOnRow(key)}
                  >
                    <td className="p-1" style={{ height: "40px" }}>
                      {calendar.settings.daysOfWeek[key]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default FirstDay;
