import { Resizable } from "re-resizable";
import React from "react";
import { useSelector } from "react-redux";
import { getGadgetBrowserInfo, getGadgetCategory, getGadgetCollapsed } from "../../../redux/selectors";
import Category5 from "./category5/Category5";
import Category1 from "./category 1/Category1";
import Category2 from "./category 2/Category2";

const Gadget = React.memo(({ id, provided }) => {
  const collapsed = useSelector((state) => getGadgetCollapsed(state, id));
  const browserInfo = useSelector((state) => getGadgetBrowserInfo(state, id));
  const category = useSelector((state) => getGadgetCategory(state, id));
  const [size, setSize] = React.useState({ width: "100%", height: 350 });
  const fullScreen = useSelector((state) => state.portal.fullWindow);

  const style = {
    borderRadius: "8px",
    // border: "solid 3px var(--bs-gray-500)",
    overflowY: "hidden",
    width: "100%",
  };

  const handleResize = React.useCallback((event, direction, ref, delta) => {
    setSize((prevSize) => ({
      width: prevSize.width,
      height: ref.offsetHeight,
    }));
  });

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{
        ...provided.draggableProps.style,
        height: fullScreen && fullScreen == id ? "100%" : collapsed ? 56 : size.height,
        display: fullScreen ? (fullScreen == id ? "block" : "none") : "block",
        marginBottom: "1rem",
        borderRadius: "8px",
      }}
      className="shadow"
    >
      <Resizable
        style={style}
        size={{
          height: fullScreen && fullScreen == id ? "100%" : collapsed ? 56 : size.height,
        }}
        minHeight={collapsed ? 56 : { 5: browserInfo?.totalcount > 0 ? 96 : 56 }[category] ?? 96}
        onResize={handleResize}
        enable={{
          top: false,
          right: false,
          bottom: fullScreen ? (fullScreen == id ? false : true) : true,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
      >
        {
          {
            1: <Category1 id={id} dndProps={provided.dragHandleProps} />,
            2: <Category2 id={id} dndProps={provided.dragHandleProps} />,
            5: <Category5 id={id} dndProps={provided.dragHandleProps} />,
          }[category]
        }
      </Resizable>
    </div>
  );
});

export default Gadget;
