import React from "react";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";

const DatePicker = ({ calendarApi, events }) => {
  const [value, setValue] = React.useState(null);

  const hasEvent = (date) =>
    events.some((event) => {
      const eventsDate = new Date(event.start);
      return getOnlyDate(eventsDate) === getOnlyDate(date);
    });

  const handleDayClick = (date) => {
    calendarApi && calendarApi.gotoDate(date.toISOString());
    setValue(date);
  };

  React.useEffect(() => {
    calendarApi?.view?.activeStart && setValue(calendarApi?.view?.activeStart);
  }, [calendarApi]);
  return (
    <div className="fc-picker-calendar-container ps-1">
      <Calendar
        locale="el"
        value={value}
        prev2Label={null}
        next2Label={null}
        formatShortWeekday={(locale, date) => date.toLocaleDateString(locale, { weekday: "narrow" })}
        onClickDay={handleDayClick}
        tileContent={({ date, view }) =>
          view === "month" ? (
            <div>
              {hasEvent(date) ? (
                <span className="event-indicator">●</span>
              ) : (
                <span>&nbsp;</span> // Empty span to keep alignment
              )}
            </div>
          ) : null
        }
      />
    </div>
  );
};

export default DatePicker;

const getOnlyDate = (date) => [date.getFullYear(), (date.getMonth() + 1).padLeft(), date.getDate().padLeft()].join("-");
