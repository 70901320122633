import React from "react";
import { TiDeleteOutline } from "react-icons/ti";
import { BsCalendar3 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { setDateRanges, setFilter } from "../../../redux/features/ui/uiSlice";
import { getCommandParams, getFilters } from "../../../redux/selectors";
import getValueFromFilterArray from "../../functions/getValueFromFilterArray";
import { setError } from "../../../redux/features/modals/modalsSlice";

const S1DateRange = ({ element, tabID, screenState, parentIndex }) => {
  const isDateTime = element.dataType == "DateTime";
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const dispatch = useDispatch();
  const filters = useSelector((state) => getFilters(state, tabID));
  const dateranges = useSelector((state) => state.systemParams.dateranges);

  const dateRef = React.useRef(null);
  const contRef = React.useRef(null);

  const [clear, showClear] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [viewValue, setViewValue] = React.useState("");
  const [value, setValue] = React.useState("");

  const handleClear = () => {
    setViewValue("");
    setValue("");
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
      dispatch(setDateRanges({ key: parentIndex, value: undefined, tabID }));
    } else {
      console.log("dispatch se " + screenState);
    }
  };

  const handleChange = (value) => {
    if (screenState == "dialog") {
      dispatch(
        setFilter({ tabID, table, key, value: isDateTime ? dateTimeConverter(value) : dateConverterSINGLE(value) })
      );
    }
  };

  React.useEffect(() => {
    var value = "";
    const getDtValue = () => {
      if (element.value != "") {
        const dtIndex = element.value.split(":")[1].slice(-2);
        const fromOrTo = element.value.split(":")[1].substring(0, 1) == "1" ? "from" : "to";
        value = dateranges?.[`1${dtIndex}`]?.[fromOrTo] ?? "";
      } else {
        value = element.value;
      }
    };
    if (screenState == "dialog") {
      getDtValue();

      value = filters?.text ? getValueFromFilterArray(table, key) : value;
      value = value != "" ? value : "";
      setValue(dateConverterSINGLE(value));
      setViewValue(displayConverter(value));
      dispatch(
        setFilter({
          tabID,
          table,
          key,
          value: isDateTime ? dateTimeConverter(value) : dateConverterSINGLE(value),
        })
      );
    }
  }, []);

  React.useEffect(() => {
    if (filters?.daterange?.[parentIndex]) {
      const dtIndex = filters?.daterange?.[parentIndex].slice(-2);
      var fromOrTo = "";
      if (element.name.indexOf("_TO") > -1) {
        fromOrTo = "to";
      } else {
        if (element.value.split(":").length > 1) {
          fromOrTo = element.value.split(":")[1].substring(0, 1) == "1" ? "from" : "to";
        } else {
          fromOrTo = "from";
        }
      }
      var value = dateranges?.[`1${dtIndex}`]?.[fromOrTo];
      // handleChange(value == "1899-12-30" ? { target: { value: "" } } : { target: { value } });
      setValue(value == "1899-12-30" ? "" : dateConverterSINGLE(value));
      setViewValue(value == "1899-12-30" ? "" : displayConverter(value));
      dispatch(
        setFilter({
          tabID,
          table,
          key,
          value: value == "1899-12-30" ? "" : value,
        })
      );
    }
  }, [filters?.daterange?.[parentIndex]]);

  React.useEffect(() => {
    if (screenState == "dialog") {
      if (filters?.data?.[table]?.[key] && filters?.data?.[table]?.[key] != "") {
        setValue(
          filters?.data?.[table]?.[key] == "1899-12-30" ? "" : dateConverterSINGLE(filters?.data?.[table]?.[key])
        );
        setViewValue(
          filters?.data?.[table]?.[key] == "1899-12-30" ? "" : displayConverter(filters?.data?.[table]?.[key])
        );
      } else {
        setViewValue("");
        setValue("");
      }
    }
  }, [filters?.data?.[table]?.[key]]);

  const handleKeyDown = (e) => {
    if (e.target.value.endsWith("/") && e.keyCode == 8) {
      const vv = e.target.value;
      const slice1 = vv.slice(0, -1);
      const slice2 = vv.slice(0, -2);
      if (slice2.endsWith("0")) {
        setViewValue(e.target.value.slice(0, -2));
      } else {
        setViewValue(e.target.value.slice(0, -1));
      }
    }
  };

  const handleInputChange = (e) => {
    let val = e.target.value.replace(/[^\d/]/g, "");
    const parts = val.split("/");
    let day, month;
    if (parts.length > 0) {
      day = checkDay(parts[0]);
      if (!day.success) return;
      val = day.value;
    }

    if (parts.length > 1) {
      day = checkDay(parts[0]);
      month = checkMonth(parts[1]);
      if (!month.success) return;
      val = `${day.value}${month.value}`;
    }

    if (parts.length > 2) {
      day = checkDay(parts[0]);
      month = checkMonth(parts[1]);
      if (!day.success) return;
      if (!month.success) return;
      val = `${parts[0]}/${parts[1]}/${parts[2]}`;
    }

    setViewValue(val);
  };

  const handleBlur = async () => {
    setIsFocused(false);
    let val;
    const today = new Date();
    let parts = viewValue.split("/");
    if (parts[0] > 0) {
      parts[0] = parts[0].padStart(2, "0");

      if (parts.length > 1) {
        parts[1] = parts[1] > 0 ? parts[1].padStart(2, "0") : (today.getMonth() + 1).toString().padStart(2, "0");
      } else {
        parts[1] = (today.getMonth() + 1).toString().padStart(2, "0");
      }

      if (parts.length > 2) {
        if (parts[2] == "") {
          parts[2] = today.getFullYear();
        } else if (parts[2] < 100) {
          parts[2] = 2000 + Number(parts[2]);
        } else {
          parts[2] = `0000${Number(parts[2])}`.slice(-4);
        }
      } else {
        parts[2] = today.getFullYear();
      }

      val = `${parts[0]}/${parts[1]}/${parts[2]}`;

      if (isValidDate(val)) {
        if (screenState == "dialog") {
          setViewValue(val);
          dispatch(
            setFilter({
              tabID,
              table,
              key,
              value: isDateTime
                ? dateTimeConverter([parts[2], parts[1], parts[0]].join("-"))
                : dateConverter(parts[2], parts[1] - 1, parts[0]),
            })
          );
        }
      } else {
        dispatch(setError({ show: true, message: "Λανθασμένη μορφή ημερομηνίας" }));
      }
    } else {
      if (screenState == "dialog") {
        dispatch(setFilter({ tabID, table, key, value: "" }));
      }

      setViewValue("");
    }
    dateRef.current.disabled = true;
  };

  return (
    <div className="s1daterange w-100" style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}>
      <div className="inputGroup" ref={contRef}>
        <label className="text-truncate" htmlFor={element.index}>
          {`${element.caption}:`}
        </label>
        <div
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}${
            element.required && value == "" ? " required" : ""
          }`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
          role="button"
        >
          <div className="d-flex w-100" onMouseEnter={() => showClear(true)} onMouseLeave={() => showClear(false)}>
            <input
              type="text"
              className={`w-100${viewValue != "" ? " date-input-has-value" : ""}`}
              name={element.name}
              disabled={element.readOnly || (screenState == "form" && !commandParams.readonly)}
              readOnly={element.readOnly || (screenState == "form" && !commandParams.readonly)}
              value={viewValue}
              autoComplete="off"
              onChange={handleInputChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => !element.readOnly && handleBlur()}
              onKeyDown={handleKeyDown}
              // onClick={() => {
              //   dateRef.current.showPicker();
              //   dateRef.current.click();
              //   dateRef.current.focus();
              // }}
            />
            {viewValue != "" && clear && (
              <div onClick={handleClear}>
                <TiDeleteOutline size="30px" role="button" />
              </div>
            )}
          </div>
          {!element.readOnly && (
            <div
              style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
              onClick={() => {
                dateRef.current.disabled = false;
                dateRef.current.showPicker();
                dateRef.current.click();
                dateRef.current.focus();
              }}
            >
              <BsCalendar3 color="rgb(64, 180, 252)" size="20px" />
            </div>
          )}
        </div>
      </div>
      <input
        type="date"
        ref={dateRef}
        value={value}
        className="text-truncate"
        onBlur={() => setIsFocused(false)}
        disabled={true}
        onChange={(e) => !element.readOnly && handleChange(e.target.value)}
        style={{
          pointerEvents: "none",
          position: "fixed",
          top: contRef?.current?.getBoundingClientRect()?.bottom,
          maxWidth: 0,
          maxHeight: 0,
          backgroundColor: "transparent",
          border: 0,
          color: "transparent",
        }}
      />
    </div>
  );
};

export default S1DateRange;

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

const dateConverter = (year, month, day) => {
  if (year != "") {
    var d = new Date(year, month, day);
    return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-");
  } else {
    return "";
  }
};

const dateConverterSINGLE = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-");
  } else {
    return value;
  }
};

const dateTimeConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return `${[d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-")} 00:00:00`;
  } else {
    return value;
  }
};

const displayConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/");
  } else {
    return value;
  }
};

const checkDay = (day) => {
  if (day > 31) {
    return { success: false };
  } else if (day > 3 || day.length == 2) {
    return { success: true, value: `${day}/` };
  } else {
    return { success: true, value: `${day}` };
  }
};

const checkMonth = (month) => {
  if (month > 12) {
    return { success: false };
  } else if ((month > 1 && month <= 12) || month.length == 2) {
    return { success: true, value: `${month}/` };
  } else {
    return { success: true, value: `${month}` };
  }
};

const isValidDate = (val) => {
  const dateTimePattern = /^\d{2}\/\d{2}\/\d{4}$/;
  console.log(val);
  if (!dateTimePattern.test(val)) return false;

  const [day, month, year] = val.split("/").map(Number);
  const date = new Date(year, month - 1, day);

  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
};
