import React from "react";
import { BsChevronDown } from "react-icons/bs";

const CrmDropDownPrinter = (props) => {
  const boundRef = React.useRef(null);
  const tableRef = React.useRef(null);
  const pickerRef = React.useRef(null);

  const [openPicker, setOpenPicker] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);

  React.useEffect(() => {
    if (openPicker) {
      const clickListener = (e) => {
        const cliii = pickerRef.current.contains(e.target) || boundRef.current.contains(e.target);
        if (!cliii) {
          setOpenPicker(false);
          setIsFocused(false);
        }
      };

      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [openPicker]);

  return (
    <div className="s1stringmulti w-100" style={{ flex: `100%`, overflow: "hidden" }}>
      <div className="inputGroup">
        <label className="text-truncate" htmlFor={props.name}>
          {props.label}:
        </label>
        <div
          ref={boundRef}
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
          role="button"
        >
          <div className="d-flex w-100">
            <input
              role="button"
              className="w-100"
              style={{ backgroundColor: "var(--bs-body-bg)" }}
              value={props.value}
              readOnly={true}
              onClick={() => {
                setOpenPicker((x) => !x);
                setIsFocused((x) => !x);
              }}
            />
          </div>
          <div
            role="button"
            onClick={() => {
              setOpenPicker((x) => !x);
              setIsFocused((x) => !x);
            }}
          >
            <BsChevronDown color="rgb(64, 180, 252)" size="30px" />
          </div>
        </div>
      </div>
      {openPicker && (
        <div
          className="input-picker-div custom"
          ref={pickerRef}
          style={{
            left: boundRef.current.getBoundingClientRect().left,
            width: boundRef.current.getBoundingClientRect().width,
            top:
              boundRef.current.getBoundingClientRect().bottom -
              (boundRef.current.getBoundingClientRect().bottom + 200 > window.innerHeight
                ? 200 + boundRef.current.getBoundingClientRect().height
                : 0),
            height: "200px",
            overflow: "hidden",
          }}
        >
          <div
            className="input-picker w-100 h-100"
            style={{
              overflow: "auto",
            }}
          >
            <table className="input-picker-table w-100" ref={tableRef}>
              <tbody>
                {Object.keys(props?.data).map((item, index) => {
                  if (item != "XPS")
                    return (
                      <tr
                        key={index}
                        className={`input-picker-table-row`}
                        onClick={() => {
                          setOpenPicker(false);
                          setIsFocused(false);
                          props.function(item);
                        }}
                      >
                        <td className="py-1 px-2" style={{ height: "40px" }}>
                          {props?.data?.[item]}
                        </td>
                      </tr>
                    );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default CrmDropDownPrinter;
