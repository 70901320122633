import React from "react";
import { Container } from "react-bootstrap";
import SaveRecord from "./form/SaveRecord";
import CancelEdits from "./form/CancelEdits";
import DeleteRecord from "./form/DeleteRecord";
import NavigationArrow from "./form/NavigationArrow";
import NewRecord from "./form/NewRecord";
import PrintRecord from "./form/PrintRecord";
import CalculateRecord from "./form/CalculateRecord";
import Browser from "./form/Browser";
import Dialog from "./form/Dialog";
import { getCommandParams, getObjectState } from "../../../../redux/selectors";
import { useSelector } from "react-redux";
import RelJob from "./form/RelJob";
import Refresh from "./form/Refresh";
const TopBarOnFrom = ({ tabID }) => {
  const commandParams = useSelector((state) => getCommandParams(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));

  return (
    <Container fluid className="object-top-bar form">
      <div className="d-flex justify-content-center align-items-center" style={{ gap: "1.5rem" }}>
        <Browser tabID={tabID} />
        <Dialog tabID={tabID} />
        {!commandParams.noinsert && <NewRecord tabID={tabID} />}
        {!(commandParams.readonly && objectState == "view") && <SaveRecord tabID={tabID} />}
        {!(commandParams.readonly && objectState == "view") && <CancelEdits tabID={tabID} />}
        {!commandParams.nodelete && <DeleteRecord tabID={tabID} />}
        <NavigationArrow tabID={tabID} />
        {!commandParams.noprintform && <PrintRecord tabID={tabID} />}
        <Refresh tabID={tabID} />
      </div>
      <div className="d-flex justify-content-center align-items-center" style={{ gap: "1.5rem" }}>
        <CalculateRecord tabID={tabID} />
        {commandParams.rj && <RelJob tabID={tabID} />}
      </div>
    </Container>
  );
};

export default TopBarOnFrom;
