import axios from "axios";
import store from "../redux/store";
import { getClientID, getURL } from "../redux/selectors";
import { deleteToken } from "firebase/messaging";
import { messaging } from "../firebase";

const logout = async (isLoged) => {
  const state = store.getState();
  const session = state.session;
  const url = getURL(state);
  const clientID = getClientID(state);

  session?.notificationID && "serviceWorker" in navigator && "PushManager" in window && deleteToken(messaging);
  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/logout`, {
      isLoged,
      key: session?.loginid,
      notificationID: session?.notificationID,
      url,
      clientID,
      appID: process.env.REACT_APP_SOFTONE_APPID,
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};
export default logout;
