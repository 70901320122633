import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";
import { getAccesRights, getFilters, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import { calculate } from "../../../services/calculate";
import { BsChevronDown } from "react-icons/bs";
import getSelectorData from "../../../services/getSelectorData";
import PickerSpinner from "../PickerSpinner";
import ModalAfmCheck, { afmCheckFields } from "../../../logic/afm";

const S1TextField = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getRecData(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID)?.data);
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const [isAfmCheck, setIsAfmCheck] = React.useState(false);
  const [afmCheck, setAfmCheck] = React.useState(false);

  const accessRights = useSelector((state) => getAccesRights(state, tabID));

  const currentGridLine = grid?.currentLine;

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const model = screenState == "form" && formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0];
  const vf = screenState == "form" && model.editor.split("|")[2];

  const [clear, showClear] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = React.useState("");

  const [pickerLoading, setPickerLoading] = React.useState(false);
  const [selectorData, setSelectorData] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [returnIndex, setReturnIndex] = React.useState(-1);
  const boundRef = React.useRef(null);
  const pickerRef = React.useRef(null);
  const tableRef = React.useRef(null);

  const handleCalculate = async (value) => {
    if (grid) {
      dispatch(setGridChange({ tabID, name: grid.model, key, value }));
    } else {
      dispatch(setChange({ tabID, table, key, value }));
      model?.updates && (await calculate(tabID, objectState));
    }
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(element.value);
      dispatch(setFilter({ tabID, table, key, value: element.value }));
    } else if (screenState == "form") {
      setIsAfmCheck(afmCheckFields.indexOf(element.name) > -1);
    }
  }, [screenState]);

  React.useEffect(() => {
    screenState == "form" && !grid && setValue(data?.[table]?.[0]?.[key] ?? "");
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    grid && setValue(currentGridLine?.line?.[key] ?? "");
  }, [currentGridLine?.line?.[key]]);

  React.useEffect(() => {
    if (openPicker) {
      const clickListener = (e) => {
        const cliii = pickerRef.current.contains(e.target) || boundRef.current.contains(e.target);
        if (!cliii) {
          setOpenPicker(false);
          setIsFocused(false);
        }
      };

      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [openPicker]);

  const handleChange = async (value) => {
    setIsFocused(false);
    if (screenState == "dialog") {
      if (filters?.[table]?.[key] != value) {
        dispatch(setFilter({ tabID, table, key, value: value }));
      }
    } else if (screenState == "form") {
      if (grid) {
        dispatch(setGridChange({ tabID, name: grid.model, key, value }));
      } else {
        if ((data?.[table]?.[0]?.[key] ?? "") != value) {
          if (objectState == "view") {
            await dispatch(changeToBuffer({ tabID }));
            dispatch(setObjectState({ value: "edit", tabID }));
          }
          handleCalculate(value);
        }
      }
    }
  };

  const handleClear = async () => {
    setValue("");
    setOpenPicker(false);
    setIsFocused(false);

    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else if (screenState == "form") {
      if (objectState == "view") {
        await dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ value: "edit", tabID }));
      }
      handleCalculate("");
    }
  };

  const handlePicker = async (e) => {
    e.stopPropagation();
    if (openPicker) {
      setOpenPicker(false);
      setIsFocused(false);
    } else {
      setPickerLoading(true);
      setOpenPicker(true);
      setIsFocused(true);
      const EditorArguments = Array.from(model.editor.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
      var editor = model.editor;
      EditorArguments.forEach((y) => {
        const splitted = y.split(".");
        const tableName = splitted[0];
        const fieldName = splitted[1];
        const replaceValue =
          screenState == "form"
            ? (data?.[tableName]?.[0]?.[fieldName] ?? "").split("|")[0]
            : (filters?.[tableName]?.[0]?.[fieldName] ?? "").split("|")[0];
        editor = editor.replaceAll(`[${y}]`, replaceValue);
      });
      const GetSelectorData = await getSelectorData({ value: "", editor, tabID });
      setSelectorData(GetSelectorData);
      if (GetSelectorData.success) setReturnIndex(GetSelectorData.model.findIndex((el) => el.name === vf));
      setPickerLoading(false);
    }
  };

  const handleClickOnRow = (value) => {
    setSelectedRow(value);
    setOpenPicker(false);
    handleChange(value[returnIndex]);
  };

  return (
    <div
      className="s1textfield w-100"
      data-field-name={element.name}
      style={{
        flex: `1 1 ${element.flex * 100}%`,
        overflow: "hidden",
        visibility: element.visible ? "visible" : "hidden",
      }}
    >
      <div className="inputGroup">
        {element.caption != "" && (
          <label
            className="text-truncate"
            htmlFor={element.index}
            style={{
              color: value != "" && isAfmCheck ? "rgb(64, 180, 252)" : undefined,
              cursor: value != "" && isAfmCheck ? "pointer" : "default",
            }}
            onClick={value != "" && isAfmCheck ? () => setAfmCheck(true) : undefined}
          >
            {`${element.caption}:`}
          </label>
        )}
        <div
          ref={boundRef}
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}${
            element.required && value == "" ? " required" : ""
          }`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
        >
          <div className="d-flex w-100" onMouseEnter={() => showClear(true)} onMouseLeave={() => showClear(false)}>
            <input
              className={`w-100${
                element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)
                  ? " disabled"
                  : ""
              }`}
              disabled={element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)}
              readOnly={element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)}
              value={value}
              autoComplete="off"
              name={element.name}
              onChange={(e) => {
                setValue(e.target.value);
                setOpenPicker(false);
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={(e) => handleChange(e.target.value)}
            />
            {value != "" &&
              (clear || isFocused) &&
              !(element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)) && (
                <div onClick={handleClear}>
                  <TiDeleteOutline size="30px" role="button" />
                </div>
              )}
            {element?.maskEnabled && (
              <div
                style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
                role="button"
                onClick={(e) =>
                  !(element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)) &&
                  handlePicker(e)
                }
              >
                <BsChevronDown
                  className={
                    element.readOnly || (screenState == "form" && objectState == "view" && !accessRights?.update)
                      ? "disabled"
                      : ""
                  }
                  color="rgb(64, 180, 252)"
                  size="20px"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {openPicker && (
        <div
          className="input-picker-div"
          ref={pickerRef}
          style={{
            left:
              boundRef.current.getBoundingClientRect().left + 400 > window.innerWidth
                ? boundRef.current.getBoundingClientRect().right - 400
                : boundRef.current.getBoundingClientRect().left,
            width: boundRef.current.getBoundingClientRect().width,
            top:
              boundRef.current.getBoundingClientRect().bottom -
              (boundRef.current.getBoundingClientRect().bottom + 300 > window.innerHeight
                ? 300 + boundRef.current.getBoundingClientRect().height
                : 0),
            height: "300px",
            overflow: "hidden",
          }}
        >
          {pickerLoading && <PickerSpinner />}

          <div className="input-picker w-100 h-100" style={{ overflow: "auto" }}>
            {selectorData?.success ? (
              <table className="input-picker-table w-100" ref={tableRef}>
                <tbody>
                  {selectorData.data.map((element, index) => (
                    <tr
                      key={index}
                      className={`input-picker-table-row${
                        selectedRow == element ? " input-picker-table-row-selected" : ""
                      }`}
                      onClick={() => handleClickOnRow(element)}
                    >
                      <td className="p-1 searchable" key={index} style={{ height: "40px" }}>
                        {element[returnIndex]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100">{selectorData.error}</div>
            )}
          </div>
        </div>
      )}
      <ModalAfmCheck show={afmCheck} setShow={setAfmCheck} afm={value} tabID={tabID} object={table} />
    </div>
  );
};

export default S1TextField;
