import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { newRecord, setLoading, setObjectState, setPrepareNew } from "../../../../../redux/features/ui/uiSlice";
import { TiPlus } from "react-icons/ti";
import { setSaveModal } from "../../../../../redux/features/modals/modalsSlice";
import { getAccesRights, getCurrentTab, getObjectState } from "../../../../../redux/selectors";
import preparNew from "../../../../../services/prepareNew";
import ModalSaveRecord from "./ModalSaveRecord";
import { locateInfoF } from "../../../../functions/locateInfo";

const NewRecord = ({ tabID }) => {
  const dispatch = useDispatch();
  const tab = useSelector((state) => getCurrentTab(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const accessRights = useSelector((state) => getAccesRights(state, tabID));

  const [show, setShow] = React.useState(false);

  const handleNew = async () => {
    if (objectState == "view") {
      const FormDesign = tab.formDesign;
      var locateinfo = locateInfoF(FormDesign);
      const PrepareNew = await preparNew({ locateinfo, tabID });
      PrepareNew.success && dispatch(setPrepareNew({ value: PrepareNew.data, idx: tab.idx, tabID }));
      dispatch(setLoading({ show: false, tabID }));
      // }
      dispatch(newRecord({ tabID }));
      dispatch(setObjectState({ value: "new", tabID }));
    } else {
      setShow(true);
    }
  };
  return (
    accessRights?.create != false && (
      <>
        <div
          onClick={handleNew}
          className="d-flex align-items-center justify-content-start"
          style={{ cursor: "pointer" }}
        >
          <TiPlus size="1.3rem" className="me-1" />
          Νέα
        </div>
        {show && (
          <ModalSaveRecord
            show={show}
            setShow={setShow}
            tabID={tabID}
            functionNo={() => {
              dispatch(newRecord({ tabID }));
              setShow(false);
            }}
          />
        )}
      </>
    )
  );
};

export default NewRecord;
