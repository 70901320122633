import axios from "axios";
import store from "../redux/store";
import { getClientID, getCommandByIndex, getCommandParams, getSN, getURL } from "../redux/selectors";
import { getDeviceInfo } from "../application/functions/getDeviceInfo";

const preparNew = async ({ locateinfo, tabID, forceValues }) => {
  const state = store.getState();
  const url = getURL(state);
  const clientID = getClientID(state);
  const serialnumber = getSN(state);
  var command = getCommandByIndex(state, tabID);
  const deviceInfo = await getDeviceInfo();

  if (forceValues) {
    console.log(true);

    const commandParms = getCommandParams(state, tabID);
    console.log();

    if (commandParms.fv) {
      command = command.replace(commandParms.fv, `${commandParms.fv}?${forceValues}`);
    } else {
      command += `&fv=${forceValues}`;
    }
  }
  const object = `${serialnumber}/object=${command}`;

  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "prepareNew",
        clientID,
        object,
        version: 2,
        locateinfo,
        appID: process.env.REACT_APP_SOFTONE_APPID,
        ...deviceInfo,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default preparNew;
