import React from "react";
import { Dropdown } from "react-bootstrap";
import { BsStopwatch } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { setGetData, setLoading } from "../../../../../redux/features/ui/uiSlice";
import {
  getClientID,
  getCommandByIndex,
  getCustomRelJobs,
  getFormDesign,
  getRecData,
  getSN,
  getURL,
} from "../../../../../redux/selectors";
import { useSelector } from "react-redux";
import { setError, setMessage } from "../../../../../redux/features/modals/modalsSlice";
import axios from "axios";
import { locateInfoF } from "../../../../functions/locateInfo";

const CheckInOut = ({ id, job, type, tabID }) => {
  const dispatch = useDispatch();
  const customrelJobs = useSelector((state) => getCustomRelJobs(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const data = useSelector((state) => getRecData(state, tabID));
  const sn = useSelector((state) => getSN(state));
  const command = useSelector((state) => getCommandByIndex(state, tabID));
  const clientID = useSelector((state) => getClientID(state));
  const url = useSelector((state) => getURL(state));
  const currentLang = useSelector((state) => state.settings.language);

  const handleClickOnCheckInOut = async () => {
    dispatch(setLoading({ tabID, show: true }));
    const field2Update = customrelJobs[type];
    const now = getNow();
    const updateTable = field2Update.split(".")[0];
    const updateField = field2Update.split(".")[1];
    var dataForUpdate = {};
    if (formDesign.model[updateTable].relationship == "OneToOne") {
      dataForUpdate[updateTable] = [
        {
          [updateField]: now,
        },
      ];
      const key = formDesign.key;
      const table = key.split(".")[0];
      const field = key.split(".")[1];
      const keyValue = data?.[table][0]?.[field];
      const object = command;
      var locateinfo = locateInfoF(formDesign);
      const setData = await axios
        .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
          url,
          data: {
            service: "setData",
            clientID,
            object,
            key: keyValue,
            locateinfo,
            version: 2,
            data: dataForUpdate,
            appID: process.env.REACT_APP_SOFTONE_APPID,
          },
        })
        .then((res) => res.data)
        .catch((er) => ({ success: false, error: er.message }));
      if (setData.success) {
        console.log(job);
        dispatch(setGetData({ value: setData, tabID }));
        dispatch(
          setMessage({
            message: `Επιτυχές ${job.text}`,
            show: true,
          })
        );
      } else {
        dispatch(setError({ message: setData.error, show: true }));
      }
    }

    dispatch(setLoading({ tabID, show: false }));
  };

  return (
    <Dropdown.Item
      id={`id-mn${id}`}
      className="d-flex align-items-center justify-content-start"
      onClick={handleClickOnCheckInOut}
    >
      <div className="me-1 d-flex justify-content-start align-items-center">
        <BsStopwatch />
      </div>
      <span className="text-truncate me-2">{job?.text}</span>
    </Dropdown.Item>
  );
};

export default CheckInOut;

const getNow = () => {
  var d = new Date();
  return (
    [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-") +
    " " +
    [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(":")
  );
};
