import { createAction, createSlice } from "@reduxjs/toolkit";
import { clear } from "../session/sessionSlice";
import {
  _killAllTabs,
  _killAllTabsExceptOne,
  _killTab,
  _setLoading,
  _setNewTab,
  _setTab,
  _showTabContextMenu,
  _updateTabOrder,
} from "./tabs";
import {
  _initialize,
  _setAccessRights,
  _setCustomRelJobMenuProperty,
  _setObjectState,
  _setObjectEvent,
  _setPrepareNew,
  _setRelJobMenu,
  _setScreenState,
  _setsetFormDesign,
} from "./object";
import { _setDateRanges, _setDialog, _setFilter, _setFilterText, _setSearchText, _setSortField } from "./dialog";
import { _changeToBuffer, _newRecord, _setCalculateData, _setChange, _setGetData } from "./form";
import {
  _canCalendar,
  _groupToogle,
  _resetBrowserLayout,
  _setBrowserFlexLayout,
  _setBrowserInfo,
  _setBrowserView,
  _setColumnVisibility,
  _setSelectedRow,
  _setTableData,
  _showTHeadContextMenu,
} from "./browser";
import { _setReportData, _setReportInfo } from "./report";
import { _batchEnd, _batchMessage, _batchStart } from "./batch";
import {
  _addDetailGrid,
  _addLineToGrid,
  _addMultipleLinesToGrid,
  _deleteLineFromGrid,
  _setActiveDetailLine,
  _setCurrentDetailLine,
  _setGridChange,
  _setGridFieldChange,
  _triggerGridChange,
  _updateLineOfGrid,
} from "./detailGrid";
import { _setCalendarDates } from "./calendar";
import { _editListSetGetData, _setTableFields } from "./editList";
const initialState = {
  tabID: -1,
  contextMenu: {
    tab: {
      show: false,
      tab: -1,
      x: 0,
      y: 0,
    },
    thead: {
      show: false,
      x: 0,
      y: 0,
    },
  },
  tabs: {},
  order: [],
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    //OBJECT
    setLoading: (state, action) => _setLoading(state, action),
    initialize: (state, action) => _initialize(state, action),
    setScreenState: (state, action) => _setScreenState(state, action),
    setObjectState: (state, action) => _setObjectState(state, action),
    setObjectEvent: (state, action) => _setObjectEvent(state, action),
    setAccessRights: (state, action) => _setAccessRights(state, action),
    setFormDesign: (state, action) => _setsetFormDesign(state, action),
    setRelJobMenu: (state, action) => _setRelJobMenu(state, action),
    setCustomRelJobMenuProperty: (state, action) => _setCustomRelJobMenuProperty(state, action),
    //FORM
    setPrepareNew: (state, action) => _setPrepareNew(state, action),
    setGetData: (state, action) => _setGetData(state, action),
    setCalculateData: (state, action) => _setCalculateData(state, action),
    setChange: (state, action) => _setChange(state, action),
    setGridFieldChange: (state, action) => _setGridFieldChange(state, action),
    changeToBuffer: (state, action) => _changeToBuffer(state, action),
    newRecord: (state, action) => _newRecord(state, action),
    //DIALOG
    setDialog: (state, action) => _setDialog(state, action),
    setFilter: (state, action) => _setFilter(state, action),
    setFilterText: (state, action) => _setFilterText(state, action),
    setDateRanges: (state, action) => _setDateRanges(state, action),
    setSearchText: (state, action) => _setSearchText(state, action),
    setSortField: (state, action) => _setSortField(state, action),
    //BROWSER
    setBrowserInfo: (state, action) => _setBrowserInfo(state, action),
    setTableData: (state, action) => _setTableData(state, action),
    showTHeadContextMenu: (state, action) => _showTHeadContextMenu(state, action),
    setSelectedRow: (state, action) => _setSelectedRow(state, action),
    setColumnVisibility: (state, action) => _setColumnVisibility(state, action),
    setBrowserFlexLayout: (state, action) => _setBrowserFlexLayout(state, action),
    resetBrowserLayout: (state, action) => _resetBrowserLayout(state, action),
    canCalendar: (state, action) => _canCalendar(state, action),
    setBrowserView: (state, action) => _setBrowserView(state, action),
    groupToogle: (state, action) => _groupToogle(state, action),
    //CALENDAR
    setCalendarDates: (state, action) => _setCalendarDates(state, action),
    //REPORT
    setReportInfo: (state, action) => _setReportInfo(state, action),
    setReportData: (state, action) => _setReportData(state, action),
    //DETAIL GRID
    addDetailGrid: (state, action) => _addDetailGrid(state, action),
    setActiveDetailLine: (state, action) => _setActiveDetailLine(state, action),
    setCurrentDetailLine: (state, action) => _setCurrentDetailLine(state, action),
    setGridChange: (state, action) => _setGridChange(state, action),
    triggerGridChange: (state, action) => _triggerGridChange(state, action),
    updateLineOfGrid: (state, action) => _updateLineOfGrid(state, action),
    addLineToGrid: (state, action) => _addLineToGrid(state, action),
    addMultipleLinesToGrid: (state, action) => _addMultipleLinesToGrid(state, action),
    deleteLineFromGrid: (state, action) => _deleteLineFromGrid(state, action),
    //BATCH JOB
    batchLoading: (state, action) => {
      state.tabs[action.payload.tabID].batchLoading = action.payload.value;
    },
    batchStart: (state, action) => _batchStart(state, action),
    batchEnd: (state, action) => _batchEnd(state, action),
    batchMessage: (state, action) => _batchMessage(state, action),
    //EDIT LIST
    setTableFields: (state, action) => _setTableFields(state, action),
  },
  extraReducers: (builder) => {
    //TABS
    builder.addCase(setTab, (state, action) => _setTab(state, action));
    builder.addCase(updateTabOrder, (state, action) => _updateTabOrder(state, action));
    builder.addCase(setNewTab, (state, action) => _setNewTab(state, action));
    builder.addCase(killTab, (state, action) => _killTab(state, action));
    builder.addCase(killAllTabs, (state) => _killAllTabs(state));
    builder.addCase(killAllTabsExceptOne, (state, action) => _killAllTabsExceptOne(state, action));
    builder.addCase(showTabContextMenu, (state, action) => _showTabContextMenu(state, action));
    builder.addCase(clear, () => initialState);
    //EDIT LIST
    builder.addCase(editListSetGetData, (state, action) => _editListSetGetData(state, action));
  },
});

//TABS
export const setTab = createAction("ui/tabs/set");
export const updateTabOrder = createAction("ui/tabs/updateOrder");
export const setNewTab = createAction("ui/tabs/new");
export const killTab = createAction("ui/tabs/kill");
export const killAllTabs = createAction("ui/tabs/killAll");
export const killAllTabsExceptOne = createAction("ui/tabs/killOthers");
export const showTabContextMenu = createAction("ui/tabs/context");

export const editListSetGetData = createAction("ui/editList/setData");

export const {
  setScreenState,
  setObjectState,
  setObjectEvent,
  initialize,
  setLoading,
  setAccessRights,
  setDialog,
  setFilter,
  setFilterText,
  setDateRanges,
  showTHeadContextMenu,
  setFormDesign,
  setRelJobMenu,
  setCustomRelJobMenuProperty,
  setSearchText,
  setBrowserInfo,
  setBrowserData,
  setColumnVisibility,
  resetBrowserLayout,
  setBrowserFlexLayout,
  setNextBrowserData,
  setReportInfo,
  setReportData,
  setSortField,
  setSelectedRow,
  setGetData,
  changeToBuffer,
  setCalculateData,
  setChange,
  setGridFieldChange,
  newRecord,
  setPrepareNew,
  addDetailGrid,
  setActiveDetailLine,
  setCurrentDetailLine,
  setGridChange,
  triggerGridChange,
  updateLineOfGrid,
  addLineToGrid,
  addMultipleLinesToGrid,
  deleteLineFromGrid,
  batchLoading,
  batchStart,
  batchEnd,
  batchMessage,
  canCalendar,
  setBrowserView,
  setCalendarDates,
  groupToogle,
  setTableData,
  setTableFields,
} = uiSlice.actions;

export default uiSlice.reducer;
