export const calendar = {
  objectFields: {
    SOMEETING: {
      setDataTable: "SOACTION",
      tableData: {
        text: "SOMEETING_TRDR_GENTRDR_NAME",
        fromDate: "SOMEETING_FROMDATE",
        toDate: "SOMEETING_FINALDATE",
        allDay: "SOMEETING_CCCD1WEBCRMALLDAY",
        title: "SOMEETING_CCCD1WEBCRMTITLE",
        description: "SOMEETING_CCCD1WEBCRMDESCRIPTION",
      },
      fromDate: "SOMEETING.FROMDATE",
      toDate: "SOMEETING.FINALDATE",
      allDay: "CCCD1WEBCRMALLDAY",
      title: "CCCD1WEBCRMTITLE",
      description: "CCCD1WEBCRMDESCRIPTION",
    },
  },
  objects: ["SOMEETING", "SOACTION"],
  views: {
    GRE: { dayGridMonth: "Μήνας", dayGridWeek: "Εβδομάδα", timeGridDay: "Ημέρα" },
    ENG: { dayGridMonth: "Month view", dayGridWeek: "Week view", timeGridDay: "Day view" },
  },
  settings: {
    daysOfWeek: {
      1: "Δευτέρα",
      2: "Τρίτη",
      3: "Τετάρτη",
      4: "Πέμπτη",
      5: "Παρασκευή",
      6: "Σάββατο",
      0: "Κυριακή",
    },
    slots: [
      ["5 λεπτά", "00:05:00"],
      ["10 λεπτά", "00:10:00"],
      ["15 λεπτά", "00:15:00"],
      ["30 λεπτά", "00:30:00"],
      ["1 ώρα", "01:00:00"],
    ],
    times: [
      "00:00",
      "00:30",
      "01:00",
      "01:30",
      "02:00",
      "02:30",
      "03:00",
      "04:00",
      "04:30",
      "05:00",
      "05:30",
      "06:00",
      "06:30",
      "07:00",
      "07:30",
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
      "21:00",
      "21:30",
      "22:00",
      "22:30",
      "23:00",
      "23:30",
    ],
  },
};
