import React from "react";
import { useDispatch, useSelector } from "react-redux/es";
import { set } from "../../../redux/features/settings/settingsSlice";

const CalendarColour = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.app);

  const [value, setValue] = React.useState("#0d6efd");

  const handleChange = (e) => {
    var ar;
    const { r, g, b } = hex2rgb(e.target.value);
    if (settings.calendarColor.split(";").length > 0) {
      ar = settings.calendarColor.split(";");
      ar[0] = rgb2softone(r, g, b);
    } else {
      ar = [rgb2softone(r, g, b), 0, 0, 0, 0, 0];
    }
    setValue(e.target.value);
    dispatch(set({ key: "calendarColor", value: ar.join(";") }));
  };

  React.useEffect(() => {
    if (settings.calendarColor) {
      if (settings.calendarColor.split(";").length == 6 && settings.calendarColor.split(";")[0] > 0) {
        const { r, g, b } = softone2rgb(settings.calendarColor.split(";")[0]);
        setValue(rgbToHex(r, g, b));
      }
    }
  }, []);

  return (
    <div className="custom w-100" style={{ flex: `1 1 100%`, overflow: "hidden" }}>
      <div className="inputGroup">
        <label className="text-truncate">Χρώμα:</label>
        <div className={`input-with-buttons`} style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }} role="button">
          <div className="d-flex w-100">
            <input
              role="button"
              type="color"
              className={`w-100`}
              readOnly={true}
              value={value}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarColour;

const hex2rgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const componentToHex = (c) => {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
};

const rgbToHex = (r, g, b) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

const softone2rgb = (code) => {
  const r = code % 256;
  const g = (code / 256) % 256;
  const b = (code / 65536) % 256;
  return { r: parseInt(r), g: parseInt(g), b: parseInt(b) };
};

const rgb2softone = (r, g, b) => {
  return r + 256 * g + 65536 * b;
};
